import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHome, faSearch, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from '@themesberg/react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import RegistrationForm from "../Registrations/RegistrationForm";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { downloadHeaders } from "../../ProData";
import {Helmet} from 'react-helmet-async';
import ListCatalogueRequest from "../catalogueRequest/ListCatalogueRequest";

const config = require('../../config.json');

//import { TransactionsTable } from "../../components/Tables";

export default () => {
  const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, subscriberDetails, setsubscriberDetails } = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('ApplicationID');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [reqCollapsed, setReqCollapsed] = useState(true);
  const [imageFile, setImageFile] = useState('');
  const [batchYear, setBatchYear]= useState([]);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);
  const [formMode, setFormMode]=useState('View');
  const [requestDetails, setRequestDetails] = useState([]);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }

  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }

  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    //setSearchValue(date_year);
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=async(event, item, mode)=>{
    event.preventDefault();
    try{
    //  console.log("Item : ", item);
    setLoaded(false);
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;
    const fetchOption="SubscriberID";
    await axios.get(`${config.api.invokeURL}/catalogue_request/search?fetchOption=${fetchOption}&fetchKey=${item.applicationNo}`,{
      headers: {
        Authorization: access_token,
        'x-api-key': config.api.key
      }}, 
  ).then((response)=>{
    console.log("Response : ", response.data);
    if(item.avatar===undefined){
      item.avatar="";
    }
    const {applicationNo, guideAddress, guideAreaCode, guideContactNo,guideDesignation,guideEmailID, guideName, institutionAddress, institutionContactNo, institutionEmailID, institutionName, materialRequired,researchProjectTitle, institutionAreaCode }=response.data[0];
    item.guideAddress=guideAddress;
    item.guideContactNo=guideContactNo;
    item.guideDesignation=guideDesignation;
    item.guideEmailID=guideEmailID;
    item.guideName=guideName;
    item.institutionAddress=institutionAddress;
    item.institutionContactNo=institutionContactNo;
    item.institutionEmailID=institutionEmailID;
    item.institutionName=institutionName;
    item.materialRequired=materialRequired;
    item.researchProjectTitle=researchProjectTitle;
    item.institutionAreaCode=institutionAreaCode;
    item.applicationNo=applicationNo;
    item.guideAreaCode=guideAreaCode;
    setsubscriberDetails(item);
    setFormMode(mode);
    toggle();
    /*if(mode==="Edit"){
      newToggle();
    }else{
      toggle();
    }*/
    setLoaded(true);
  }).catch((error)=>{
      //console.log(error);
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
      //setDispMsg(error.message);
  })
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  const toggle = () => {
    setCollapsed(!collapsed);
  }

  const viewRequestToggle=()=>{
    setReqCollapsed(!reqCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  

      }else{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;  
      await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
       //console.log("response : ", response.data[0]);
       //     console.log("order status")
            setSearchResults(response.data);
        
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: response.data.length + " - member's records found!"
      });  
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  /*const validateFields=()=>{
    const {title,firstName, dateOfBirth, gender, maritialStatus,
      occupation, religion, mobileNumber, emailID, addressStreet, addressState,
      addressPincode, nationality, motherTongue, englishProficiency}=profileDetails;
      var returnValue="passed";
      if(title===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Title cannot be Empty"
      }); 
      returnValue="failed";
    }else  if(firstName===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Name cannot be Empty"
    }); 
    returnValue="failed";
  }else  if(dateOfBirth===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Date of Birth cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(gender===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Gender cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(maritialStatus===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Maritial Status cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(occupation===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Occupation cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(religion===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Religion cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(mobileNumber===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Mobile Number cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(emailID===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Email Address cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(addressStreet==="" || addressPincode==="" || addressState===""){
      console.log(addressStreet, addressState, addressPincode);
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Address cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(nationality===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Nationality cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(motherTongue===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Mother Tongue cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(englishProficiency===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "English Proficiency cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(imageFile===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Attach your Photo. Photo is must for registration!"
  }); 
  returnValue="failed";
  }else  if(idProofFile===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Attach one Govt approved photo ID proof! Its must."
  }); 
  returnValue="failed";
  }else  if(certProofFile===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Attach UG/PG certificate. It's mandatory for registration."
  }); 
  returnValue="failed";
  }else  {
    returnValue="passed";
  
  }
  window.scrollTo(0,0);
  return returnValue;
  }*/

  const postOnS3 = async(applicationno,timageFile,inputType)=>{
    try{
        //  console.log("NewImage : ", newImage);
        var tKeyName="";
        var newFileName="";
        if(inputType==="Profile"){
          tKeyName="avatar/"+applicationno;   
          newFileName=applicationno+".jpeg";
        }else if (inputType==="IDProof"){
          tKeyName="avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_idproof.jpeg"; 
        }else if (inputType==="CERTProof"){
          tKeyName="avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_certproof.jpeg"; 
        }   
      
      //console.log("newfilename :", newFileName);
     // const newImage="";
      var file = timageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const bucketName=config.s3.bucketName + tKeyName;
     // await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
     // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
      await axios.get(`${config.api.invokeURL}/dashboard/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
               Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
         //  console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
            //  setNewProfileImage(newFileName);
              //  fetchGalleryImages();
                // setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
             }
        }).catch((error)=>{
           // console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
     // console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    }
  
  }

  const handleSubmit=async(event)=>{
    event.preventDefault();
    try{
    alertClose();
   const validationResult="passed";
    if(validationResult==="passed"){
      //setLoaded(false);
      const { applicationNo, applicantName, applicantContactNo, applicantEmailID, applicantAreaCode, applicantAddress, institutionName,
        institutionAddress, institutionContactNo, institutionEmailID, guideName, applicationStatus, guideAreaCode, institutionAreaCode,
        guideDesignation, guideAddress, guideContactNo, guideEmailID, specialAccess, comments }=subscriberDetails;
      const applicationno= applicationNo;
      const tupdatedOn=await generateDate();
      var tavatar="";
      if(imageFile){
        tavatar= applicationno+".jpeg";
      }else{
        tavatar=applicationno;
      }
      //const applicationStatus="Pending Approval";
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;

      const params={
        applicationNo: applicationno, applicantName:applicantName, applicationStatus:applicationStatus,
        applicantContactNo:applicantContactNo, applicantEmailID:applicantEmailID, applicantAreaCode:applicantAreaCode, applicantAddress:applicantAddress,
        institutionName:institutionName, institutionAddress:institutionAddress, institutionContactNo:institutionContactNo, institutionEmailID:institutionEmailID, 
        guideName:guideName, guideDesignation:guideDesignation, institutionAreaCode:institutionAreaCode, guideAreaCode:guideAreaCode,
        guideAddress:guideAddress, guideContactNo:guideContactNo, guideEmailID:guideEmailID, updatedBy:loggedUser.name, updatedOn:tupdatedOn, 
        specialAccess:specialAccess,comments:comments};
        
        console.log("Params : ", params);
        
        await axios.patch(`${config.api.invokeURL}/dashboard/registration/${applicationno}`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{

       //   console.log("response : ", response); 

       if(imageFile){
          await postOnS3(applicationno, imageFile,"Profile");
       }
       await handleSearch();
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: applicationno + " - Successfully Updated."
        }); 
        window.scrollTo(0,0);
        toggle();
        setLoaded(true);
        }).catch((error)=>{
       //   console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
    }
      
      }catch(error){
  //      console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const handleSearchTopicSelection=(event)=>{
    if(event.target.value==="SpecialAccess"){
      setSearchValue("Yes");
    }else if(event.target.value==="Status"){
      setSearchValue("Active");
    }else if(event.target.value==="JoiningYear"){
      setSearchValue(batchYear[0]);
    }else{
      setSearchValue('')
    }
    setSearchTopic(event.target.value)
  }

  const handleRequestView =async(event, item)=>{
    event.preventDefault();
    try{
      //  console.log("Item : ", item);
      setLoaded(false);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const fetchOption="SubscriberID";
      await axios.get(`${config.api.invokeURL}/catalogue_request/search?fetchOption=${fetchOption}&fetchKey=${item.applicationNo}`,{
        headers: {
          Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
      console.log("Response : ", response.data);
      setRequestDetails(response.data);
      viewRequestToggle();
      setLoaded(true);
    }).catch((error)=>{
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
    })
      }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      setLoaded(true);
      }
  }

  return (
  <>
    <Helmet>
      <title>Member Search - Admin Office - Archives</title>
      <meta name="description" content="Member Search - Admin Office - Archives" />
      <meta name="keywords" content="Member Search - Admin Office - Archives" /> 
    </Helmet>

      <Preloader show={loaded ? false : true} />
      <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
      </Alert>
        
        {/*View*/}
        <div>
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'900px'}} >
          <div  ref={componentModelRef}>
            <ModalHeader  toggle={toggle}>
               Registration Form - {formMode}
            </ModalHeader>
            <ModalBody >    
              <RegistrationForm  formType={formMode} /> 
             {formMode==="Edit"? <div className="mt-3 text-center">
              <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Submit</Button>
            </div>:null}

            </ModalBody>
            <ModalFooter>
            {' '}
            <ReactToPrint
              content={reactToPrintContentModal}
              documentTitle="AwesomeFileName"
              trigger={reactToPrintTriggerModal}
            />
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>       
        </Modal>
        </div>
        
         {/*View Request*/}
         <Modal toggle={viewRequestToggle} isOpen={!reqCollapsed} style={{top:'10%', maxWidth:'800px'}}>
          <ModalHeader toggle={viewRequestToggle}>
          View Catalogue Request
          </ModalHeader>
          <ModalBody>    
          <div>
            <ListCatalogueRequest searchResults={requestDetails} />
          </div>
          </ModalBody>
          <ModalFooter>
            {' '}
            <Button onClick={viewRequestToggle}>
                Close
            </Button>
          </ModalFooter>
        </Modal>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Member</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Member Details</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="ApplicationID" value={searchTopic} onChange={(event)=>{handleSearchTopicSelection(event)}}>
                <option value="ApplicationID">Application No</option>
                <option value="ApplicatantName">Name</option>
                  <option value="EmailID">Email Address</option>
                  <option value="JoiningYear">Joining Year</option>
                  <option value="SpecialAccess">Special Access</option>
                  <option value="Status">Status</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              {searchTopic==="JoiningYear"?           
              <Form.Select value={searchValue} 
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {batchYear.map(item => {
                    return (
                    <option key={item} value={item}>{item}</option>
                    );
                  })}
            </Form.Select>:searchTopic==="Status"?           
              <Form.Select value={searchValue} 
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                <option value="Active">Active</option>
                <option value="In-Active">In Active</option>
                <option value="Pending Approval">Pending Approval</option>
                <option value="Pending Payment">Pending Payment</option>
                <option value="Expired">Expired</option>
                <option value="Declined">Declined</option>
            </Form.Select>:searchTopic==="SpecialAccess"?           
              <Form.Select value={searchValue} 
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>:
            <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

      <Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Registration No</th>
                <th>Name</th>
                <th>Phone No</th>
                <th>Email Address</th>
                <th>Start Date</th>
                <th>Status</th>
               <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {searchResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.applicationNo}</td>
            <td>{item.applicantName}</td>
            <td>{item.applicantAreaCode}-{item.applicantContactNo}</td>
            <td>{item.applicantEmailID}</td>
            <td>{item.registrationDate}</td>
            <td>{item.applicationStatus}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "Edit")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleRequestView(event, item)}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> View Request
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
      </Table>
    </>
  );
};
