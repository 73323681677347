
export const Routes = {
    // pages
    //Presentation: { path: "/" },
    LandingPage: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },
    WelcomeDash: { path: "/welcome" },
    StudentMgmt:{path:"/account"},

    //auth
    Signin: { path: "/dashboard/sign-in" },
    Signup: { path: "/dashboard/sign-up" },
    ForgotPassword: { path: "/dashboard/forgot-password" },
    ResetPassword: { path: "/dashboard/reset-password" },
    Lock: { path: "/dashboard/lock" },
    NotFound: { path: "/dashboard/404" },
    ServerError: { path: "/dashboard/500" },
    InProgress: {path:"/dashboard/inprogress"},
    
    //insights
    Insights:{path:"/dashboard/insights"},

    //registration
    ManageRegistration: { path: "/ManageRegistration" },
    NewRegistration: {path:"/NewRegistration"},

    //catalogue request
    PendingCatalogue: { path: "/pending_catalogue" },
    SearchCatalogue: { path: "/catalogue/search" },
    NewCatalogue: { path: "/catalogue/new" },

    CourseMaterialInfo: { path: "/CourseMaterialInfo" },
    CourseVideosInfo: { path: "/CourseVideosInfo" },
    CourseVideosAdd: { path: "/CourseVideosAdd" },

    CourseSchedular: { path: "/CourseSchedular" },
    StudentsDetails: {path: "/StudentsDetails"},
    ManageFee: {path: "/Fees/ManageFee"},
    ListFees: {path:"/Fees/List"},

    //inbox
    /*InboxNewNotification:{path:"/inbox/notification/new"},
    InboxOpenQuery:{path:"/inbox/query/open"},*/


    //exam
    /*ExamInfo: {path: "/examinfo"},
    AssignExam:{path: "/examassignment"},
    LessonJourney:{path: "/lessonjourney"},*/

    //evaluation
    evaluation:{path:"/dashboard/evaluation"},

    //Images
    /*ImageViewer: { path: "/imageviewer" },
    SliderViewer: { path: "/dashboard/slider-view" },
    GalleryViewer: { path: "/dashboard/gallery-view" },*/

    //InfoDesk
    InfoDesk:{path:"/dashboard/news-section"},

    //Students
    CoursesOffered: { path: "/courses-offered" },
    MyProfile: { path: "/dashboard/my-profile" },
    MyPayments: { path: "/dashboard/my-payments" },
    MyCourses: { path: "/dashboard/my-courses" },

    //Order
    OrderStatus:{path: "/studentcorner/order/orderstatus"},

    //staff
    StaffRegistration:{path:"/dashboard/admin/registration"},
    StaffProfile:{path:"/dashboard/admin/myprofile"},


};