import React from "react";
import { Button, FormCheck } from '@themesberg/react-bootstrap';
import { Col, Row, Card, Form } from 'react-bootstrap';

export default function CatalogueForm(props) {
    const {catalogue, setCatalogue, selfNoData, category, 
    handleCatalogueEdit, handleCatalogueNew, viewOnly, processCatalogueSelect, catalogueFormType}=props;

  return (
    <>
    <div>
      <div>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
        <Row>
        <Col md={6} className="mb-3">
              <Form.Group id="videoName">
                <Form.Label>Book Title</Form.Label>
                <Form.Control required type="text" placeholder="Book Title" value={catalogue.title}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setCatalogue({...catalogue, title:(event.target.value)})}}  
               />
              </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
              <Form.Group id="accessionNo">
                <Form.Label>Accession No</Form.Label>
                <Form.Control required type="text" placeholder="Accession No" value={catalogue.catalogueID}
                                 disabled 
                                 onChange={(event)=>{setCatalogue({...catalogue, catalogueID:(event.target.value)})}}  
               />
              </Form.Group>
        </Col>
        </Row> 
        <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Author</Form.Label>
                <Form.Control required type="text" placeholder="Author Name" value={catalogue.author}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setCatalogue({...catalogue, author:(event.target.value)})}}  
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group className="mb-2">
            <Form.Label>   </Form.Label>
            <Form.Check type="checkbox">
                <FormCheck.Input id="donationCheck5" className="me-2" disabled={viewOnly}
                    checked={catalogue.specialAccess} onChange={(event)=>{setCatalogue({...catalogue,specialAccess:!catalogue.specialAccess})}}/>
                <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Special Access</FormCheck.Label>
            </Form.Check>
            </Form.Group>
            </Col>
          </Row> 
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Date Added</Form.Label>
                <Form.Control required type="date" value={catalogue.addedDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setCatalogue({...catalogue, addedDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Select value={catalogue.catalogueStatus}
                disabled={viewOnly}
                onChange={(event)=>{setCatalogue({...catalogue, catalogueStatus:event.target.value})}}
               >
                  <option value="Active">Active</option>
                  <option value="In-Active">In-Active</option>
                 </Form.Select>
              </Form.Group>
            </Col>
          </Row>  
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="videoType">
                <Form.Label>Category</Form.Label>
                <Form.Select value={catalogue.category}
                disabled={viewOnly}
                onChange={(event)=>{processCatalogueSelect(event.target.value)}}
               >
                  {category.map(item => {
                    return (<option key={item.catid} value={item.category}>{item.category}</option>);
                  })}
              </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Self No</Form.Label>
                <Form.Select value={catalogue.selfNo}
                disabled={viewOnly}
                onChange={(event)=>setCatalogue({...catalogue, selfNo:event.target.value})}
               >
                  {selfNoData.map(item => {
                    return (<option key={item.catid} value={item.catid}>{item.catid}</option>);
                  })}
              </Form.Select>
              </Form.Group>
            </Col>
          </Row> 
          <Row>
          <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Book No</Form.Label>
                <Form.Control type="text" value={catalogue.bookNo}
                disabled={viewOnly}
                onChange={(event)=>{setCatalogue({...catalogue, bookNo:event.target.value})}}
               />
              </Form.Group>
            </Col>

         
          <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Publisher</Form.Label>
                <Form.Control type="text" value={catalogue.publisher}
                disabled={viewOnly}
                onChange={(event)=>{setCatalogue({...catalogue, publisher:event.target.value})}}
               />
              </Form.Group>
            </Col>
            </Row>      
          <Row>
          {catalogue.catalogueStatus!=="Active" && <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Date Removed</Form.Label>
                <Form.Control required type="date" value={catalogue.endDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setCatalogue({...catalogue, endDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>}
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Comments</Form.Label>
                <Form.Control required type="textarea"  rows={5} value={catalogue.comments}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setCatalogue({...catalogue, comments:(event.target.value)})}}
                  />
              </Form.Group>
            </Col>
          </Row>  
          {catalogueFormType==="Edit"?<div className="mt-3 text-center">
                  <Button variant="primary" type="submit" onClick={(event)=>{handleCatalogueEdit(event)}}>Update</Button>
                </div>:catalogueFormType==="New"?<div className="mt-3 text-center">
                  <Button variant="primary" type="submit" onClick={(event)=>{handleCatalogueNew(event)}}>Save</Button>
                </div>:null}
</Form>
</Card.Body>
</Card>
</div>
</div>
</>
)
}
