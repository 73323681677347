import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import {Input, Table} from 'reactstrap';
import { Button } from '@themesberg/react-bootstrap';
import { phoneCode } from "../../ProData";
import { ProductContext } from '../../ProductProvider';
//const config = require('../../config.json');

export  default function CatalogRequestForm(props) {
  const { subscriberDetails, setsubscriberDetails, alertDetails, setAlertDetails } = useContext(ProductContext);
  const { formType} = props;
  const [viewOnly, setViewOnly] = useState(true);
  const [materialsRequired, setMaterialsRequired]= useState({item:''});

  useEffect(()=>{
    if(formType==="Edit"||formType==="New"){
      setViewOnly(false);
    }else{
      setViewOnly(true);
    }
  },[])

  const handleMaterialsAdd =async(event)=>{
    event.preventDefault();
    if(materialsRequired.item===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Materials Required cannot be empty"
    }); 
    window.scrollTo(0,0);
    }else{
      await setsubscriberDetails({...subscriberDetails,
        materialRequired:[...subscriberDetails.materialRequired, materialsRequired]});
        setMaterialsRequired({item:''});
    }
  }

  return (
    <div>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
        <Card border="light" className=" bg-white shadow-sm mb-4">

        <h3>Institution Details:</h3>
        <Card className="container">     
        <Row>
            <Col md={6} className="mb-3">
                <Form.Group id="institution">
                <Form.Label>Institution Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Institution Full name" 
                                disabled={viewOnly}
                value={subscriberDetails.institutionName} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, institutionName:event.target.value})}}/>
                </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
                <Form.Group id="title">
                <Form.Label>Email ID</Form.Label>
                <Form.Control required type="text" placeholder="Enter Institution Email Address" 
                                disabled={viewOnly}
                value={subscriberDetails.institutionEmailID} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, institutionEmailID:event.target.value})}}/>
                </Form.Group>
            </Col>
            </Row>
            <Row>
            {/*<Col md={6} className="mb-3">
                <Form.Group id="contactNo">
                <Form.Label>Institution Contact No</Form.Label>
                <Form.Control required type="text" placeholder="Enter Institution Contact Number" 
                value={subscriberDetails.institutionContactNo} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, institutionContactNo:event.target.value})}}/>
                </Form.Group>
                                </Col>*/}
            <Col sm={6} className="mb-3">
                  <Form.Group id="areanumber">
                    <Form.Label>Institution Contact No</Form.Label>
                    <Row>
                    <Col sm={4}>
                <Input type="select" name="institutionContactNo"
                                      value={subscriberDetails.institutionAreaCode} 
                                      disabled={viewOnly}
                                      onChange={(event)=>{setsubscriberDetails({...subscriberDetails, institutionAreaCode:(event.target.value)})}}
                                    >
                                      {phoneCode.map(item => {
                                    return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                                  })}
                      </Input>
                </Col>
                <Col sm={8}>
                <Form.Control required type="number" placeholder="Enter Institution Contact number" 
                                disabled={viewOnly}
                    value={subscriberDetails.institutionContactNo} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, institutionContactNo:event.target.value})}}/>
                </Col>
                    </Row>               
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                <Form.Group id="title">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" placeholder="Enter Institution Full Address" 
                                disabled={viewOnly}
                value={subscriberDetails.institutionAddress} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, institutionAddress:event.target.value})}}/>
                </Form.Group>
            </Col>

            </Row>
        </Card>
        <h3>Guide Details:</h3>
        <Card className="container">     
        <Row>
            <Col md={6} className="mb-3">
                <Form.Group id="gname">
                <Form.Label>Guide Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Guide name" 
                                disabled={viewOnly}
                value={subscriberDetails.guideName} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, guideName:event.target.value})}}/>
                </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
                <Form.Group id="gdesignation">
                <Form.Label>Guide Designation</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Guide Designation" 
                                disabled={viewOnly}
                value={subscriberDetails.guideDesignation} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, guideDesignation:event.target.value})}}/>
                </Form.Group>
            </Col>
            </Row>
            <Row>
          {/*} <Col md={6} className="mb-3">
                <Form.Group id="gcontactNo">
                <Form.Label>Guide Contact No</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Guide Contact Number" 
                value={subscriberDetails.guideContactNo} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, guideContactNo:event.target.value})}}/>
                </Form.Group>
                                </Col>*/}
            <Col sm={6} className="mb-3">
                  <Form.Group id="areanumber">
                    <Form.Label>Guide Contact No</Form.Label>
                    <Row>
                    <Col sm={4}>
                <Input type="select" name="guideContactNo"
                                      value={subscriberDetails.guideAreaCode} 
                                      disabled={viewOnly}
                                      onChange={(event)=>{setsubscriberDetails({...subscriberDetails, guideAreaCode:(event.target.value)})}}
                                    >
                                      {phoneCode.map(item => {
                                    return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                                  })}
                      </Input>
                </Col>
                <Col sm={8}>
                <Form.Control required type="number" placeholder="Enter Guide Contact number" 
                                disabled={viewOnly}
                    value={subscriberDetails.guideContactNo} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, guideContactNo:event.target.value})}}/>
                </Col>
                    </Row>               
                  </Form.Group>
                </Col>
          
            <Col md={6} className="mb-3">
                <Form.Group id="gamilid">
                <Form.Label>Guide Email ID</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Guide Email Address" 
                                disabled={viewOnly}
                value={subscriberDetails.guideEmailID} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, guideEmailID:event.target.value})}}/>
                </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={6} className="mb-3">
                <Form.Group id="gaddress">
                <Form.Label>Guide Address</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Guide Full Address" 
                                disabled={viewOnly}
                value={subscriberDetails.guideAddress} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, guideAddress:event.target.value})}}/>
                </Form.Group>
            </Col>
            </Row>
        </Card>
        <h3>Research Details:</h3>
        <Card className="container">     
        <Row>
            <Col md={5} className="mb-3">
                <Form.Group id="rname">
                <Form.Label>Research Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Research name" 
                                disabled={viewOnly}
                value={subscriberDetails.researchProjectTitle} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, researchProjectTitle:event.target.value})}}/>
                </Form.Group>
            </Col>
            {!viewOnly? <Col md={5} className="mb-3">
                <Form.Group id="materials">
                <Form.Label>Materials Required:</Form.Label>
                <Form.Control required type="text" placeholder="Enter material details" 
                                disabled={viewOnly}
                value={materialsRequired.item} 
                onChange={(event)=>{setMaterialsRequired({...materialsRequired, item:event.target.value})}}
                />
                </Form.Group>
            </Col>:null}
          {!viewOnly? <Col md={2} className="mb-3"> 
            <Form.Group id="butt">
                <Form.Label>   </Form.Label>
            <Button onClick={event=>handleMaterialsAdd(event)}>Add</Button>
            </Form.Group>
            </Col>:null}
        </Row>
        <Table hover responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Materials</th>
        </tr>
        </thead>
        <tbody>
        {subscriberDetails.materialRequired && subscriberDetails.materialRequired.map((item, index)=>
        <tr key={index}>
          <th scope="row">{index+1}</th>
          <td>{item.item}</td>
          {/*!viewOnly? <td>
          <FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleRemoveStudy(event, item)}}/>
        </td>:null*/}
        </tr>)}
        </tbody>
        </Table>
        </Card>
    </Card>
    </Form>
    </Card.Body>
    </Card>
    </div>
  )
}
