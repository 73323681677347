import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import { FormGroup, Input, Label } from "reactstrap";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

export default function SpecialAccessForm(props) {
    const { formType } = props;
    const { subscriberDetails, setsubscriberDetails } = useContext(ProductContext);
    const [viewOnly, setViewOnly] = useState(true);

    useEffect(()=>{
        //console.log("Sub : ", subscriberDetails);
        if(formType==="Edit" || formType==="New"){
          setViewOnly(false);
        }else{
          setViewOnly(true);
        }
      },[])

    const handleSpecialAccessChange=async(event)=>{
        event.preventDefault();
        //console.log("speicial access : ", subscriberDetails.specialAccess)
        var tspecialAccess="No";
        if(subscriberDetails.specialAccess==="Yes"){
            tspecialAccess="No";
        }else{
            tspecialAccess="Yes";
        }
        await setsubscriberDetails({...subscriberDetails, 
            specialAccess:tspecialAccess});
    }

  return (
    <div>
    <h3>Applicant Details:</h3>
    <Card className="container">     
        <Row>
            <Col md={6} className="mb-3">
            <Form.Group id="title">
                <Form.Label>Registered Date</Form.Label>
                <Form.Control required type="text" placeholder="Registered Date"           
                disabled
                value={subscriberDetails.registrationDate} />
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="title">
                <Form.Label> </Form.Label>
                <FormGroup check>
                <Input type="checkbox" checked ={subscriberDetails.specialAccess==="Yes"?true:false}
                                onChange={event=>handleSpecialAccessChange(event)}
                                disabled={viewOnly}
                         />
                <Label check>
                Special Access
                </Label>
            </FormGroup>

           {/* <Form.Check
                type='checkbox'
                id='speicalAccessCheckBox'
                label='Special Access'
                onChange={event=>handleSpecialAccessChange(event)}
                disabled={viewOnly}
            >
            <Form.Check.Input type='checkbox' 
                isValid ={subscriberDetails.specialAccess==="Yes"?true:false}
             />
            <Form.Check.Label>Special Access</Form.Check.Label>
            </Form.Check>*/}

            </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={6} className="mb-3">
            <Form.Group id="contactNo">
                <Form.Label>Start Date</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Start Date" disabled
                value={subscriberDetails.startDate} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, startDate:event.target.value})}}/>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="title">
                <Form.Label>End Date</Form.Label>
                <Form.Control required type="text" placeholder="Enter your End Date" disabled
                value={subscriberDetails.endDate} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, endDate:event.target.value})}}/>
            </Form.Group>
            </Col>  
            </Row>
        </Card>
</div>
  )
}
