import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import {Input} from 'reactstrap';
import { ProductContext } from '../../ProductProvider';
import { phoneCode } from "../../ProData";

export default function NewReistrationForm(props) {
    const { formType } = props;
    const { subscriberDetails, setsubscriberDetails } = useContext(ProductContext);
    const [viewOnly, setViewOnly] = useState(true);

    useEffect(()=>{
        if(formType==="Edit"){
          setViewOnly(false);
        }else{
          setViewOnly(true);
        }
      },[])

  return (
    <div>
    <h3>Applicant Details:</h3>
      <Card className="container">     
          <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="title">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control required type="text" placeholder="Enter your Full name"           
                  disabled={viewOnly}
                  value={subscriberDetails.applicantName} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantName:event.target.value})}}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="title">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control required type="text" placeholder="Enter your Email Address" 
                                  disabled={viewOnly}
                  value={subscriberDetails.applicantEmailID} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantEmailID:event.target.value})}}/>
                </Form.Group>
              </Col>

              </Row>
              <Row>
              {/*<Col md={6} className="mb-3">
                <Form.Group id="contactNo">
                  <Form.Label>Contact No</Form.Label>
                  <Form.Control required type="text" placeholder="Enter your Contact Number" 
                  value={applicant.applicantContactNo} onChange={(event)=>{setsubscriberDetails({...applicant, applicantContactNo:event.target.value})}}/>
                </Form.Group>
          </Col>*/}
          <Col sm={6} className="mb-3">
                <Form.Group id="areanumber">
                  <Form.Label>Contact No</Form.Label>
                  <Row>
                  <Col sm={2}>
              <Input type="select" name="courseName"
                                    value={subscriberDetails.applicantAreaCode} 
                                    disabled={viewOnly}
                                    onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantAreaCode:(event.target.value)})}}
                                  >
                                    {phoneCode.map(item => {
                                  return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                                })}
                    </Input>
              </Col>
              <Col sm={10}>
              <Form.Control required type="number" placeholder="Enter your Contact number" 
                              disabled={viewOnly}
                  value={subscriberDetails.applicantContactNo} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantContactNo:event.target.value})}}/>
              </Col>
                  </Row>               
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="title">
                  <Form.Label>Address</Form.Label>
                  <Form.Control required type="text" placeholder="Enter your Full Address" 
                                  disabled={viewOnly}
                  value={subscriberDetails.applicantAddress} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantAddress:event.target.value})}}/>
                </Form.Group>
              </Col>

              </Row>
      </Card>
</div>
  )
}
