import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import { faEye, faHome, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Breadcrumb, Dropdown, Table } from '@themesberg/react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from 'aws-amplify';
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { downloadPaymentHeaders } from "../../ProData";
import RegistrationForm from '../Registrations/RegistrationForm';
const config = require('../../config.json');

export default function PendingCatalogue() {
    const { subscriberDetails, setsubscriberDetails, loggedUser, alertClose, validateLogin, alertDetails, setAlertDetails, generateDate } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
    const csvLinkEl = React.createRef();
    const componentRef = React.useRef(null);

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          alertClose();
          fetchCatalogueRequestDetails();
        }else{
        validateLogin();
        alertClose();
        }
        return()=>{
          alertClose();
        }
    },[])

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
      }, [componentRef.current]);
    
      const reactToPrintTrigger = React.useCallback(() => {
        return <Button variant="outline-primary"  size="sm">Print</Button>;
      }, []);    

    const toggle = () => {
        setCollapsed(!collapsed);
    }

    const fetchCatalogueRequestDetails=async()=>{
        try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const fetchOption="CatReqStatus";
            const fetchKey="Pending";
            await axios.get(`${config.api.invokeURL}/catalogue_request/search?fetchKey=${fetchKey}&fetchOption=${fetchOption}`,{
                headers: {
                  Authorization: access_token,
                  'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
                setSearchResults(response.data);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: response.data.length + " - Pending Request Found"
                });  
                //console.log(response);
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                //setDispMsg(error.message);
            })
        
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
        }
    }

    const handleApplicationView=async(event, item)=>{
        event.preventDefault();
        //console.log("Item : ", item);
        try{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const fetchOption="ApplicationID";
          await axios.get(`${config.api.invokeURL}/dashboard/search?fetchOption=${fetchOption}&fetchKey=${item.applicationNo}`,{
            headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
          //console.log("Response : ", response.data);
          if(item.avatar===undefined){
            item.avatar="";
          }
          const {applicantName,applicationStatus,applicantEmailID,applicantAreaCode, applicantAddress, applicantContactNo, specialAccess, startDate, endDate, registrationDate, applicationNo }=response.data[0];
          item.applicantName=applicantName;
          item.applicationStatus=applicationStatus;
          item.applicantEmailID=applicantEmailID;
          item.applicantAreaCode=applicantAreaCode;
          item.applicantAddress=applicantAddress;
          item.specialAccess=specialAccess;
          item.applicantContactNo=applicantContactNo;
          item.startDate=startDate;
          item.endDate=endDate;
          item.registrationDate=registrationDate;
          setsubscriberDetails(item);
          toggle();
          //setApplicationDetails(response.data);
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: applicationNo + " successfully fetched!" 
            });  
            setLoaded(true);
        }).catch((error)=>{
            //console.log(error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            //setDispMsg(error.message);
        })
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
        }
    }

    const handleCatalogueAction=async(event, tstatus)=>{
        event.preventDefault();
        try{
            const tDate= await generateDate();
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const params={
                requestID:subscriberDetails.requestID,
                requestStatus:tstatus,  
                updatedBy:loggedUser.name,
                updatedOn:tDate,
            }
            //console.log("Params : ", params);
            await axios.patch(`${config.api.invokeURL}/catalogue_request`, params,{
                headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                //  console.log("Response : ", response);
                fetchCatalogueRequestDetails();
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: subscriberDetails.requestID + " - Successfully Updated!"
                }); 
                toggle();
                window.scrollTo(0,0);
                setLoaded(true);            
                })
                .catch((error)=>{
                    setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Error Occured : " + error.message
                }); 
                window.scrollTo(0,0);
                setLoaded(true);
                })
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
        }
    }

    const handleDownload =async(event)=>{
        event.preventDefault();
        csvLinkEl.current.link.click();
      }

  return (
  <>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>
    {/*View*/}
    <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
        <ModalHeader toggle={toggle}>
        </ModalHeader>
        <ModalBody>    
            <RegistrationForm formType={"View"}/> 
        </ModalBody>
        <ModalFooter>
        {subscriberDetails.requestStatus==="Pending"?<>
        <Button onClick={event=>handleCatalogueAction(event, "Approve")}>
            Approve
        </Button>
        <Button onClick={event=>handleCatalogueAction(event, "Declined")}>
            Declined
        </Button>
        </>:null}
        <Button onClick={toggle}>
            Close
        </Button>
        </ModalFooter>
    </Modal>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Catalogue</Breadcrumb.Item>
            <Breadcrumb.Item>Request</Breadcrumb.Item>
            <Breadcrumb.Item active>Pendng Status</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Catalogue Request - Pending Request Status</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadPaymentHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
    <Table responsive hover className="user-table align-items-center">
        <thead>
            <tr>
                <th>#</th>
                <th>Request ID</th>
                <th>Subscriber ID</th>
                <th>Requested Date</th>
                <th>Project Title</th>
                <th>Status</th>
               <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {searchResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.requestID}</td>
            <td>{item.applicationNo}</td>
            <td>{item.requestDate}</td>
            <td>{item.researchProjectTitle}</td>
            <td>{item.requestStatus==="Pending Approval"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.requestStatus}</p>:
            item.requestStatus==="Approved"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.requestStatus}</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.requestStatus}</p>}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
        </tbody>
    </Table>
  </>
  )
}
