import React, { useEffect, useState, useContext } from "react";
import { Button, ButtonGroup, Breadcrumb, FormCheck } from '@themesberg/react-bootstrap';
import {Alert} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form } from 'react-bootstrap';
import { Auth } from "aws-amplify";
import axios from "axios";
import { catalogCategoryData } from "../../ProData";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import CatalogueForm from "./CatalogueForm";
//import VideoUploader from "./VideoUploader";
//import PosterUploader from "./PosterUploader";
const config = require('../../config.json');

export default function AddCatalogue() {
    const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, catalogueFormType, setCatalogueFormType, catalogue, setCatalogue } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(true);
    const [viewOnly, setViewOnly] = useState(true);
    const [selfNoData, setSelfNoData]= useState(catalogCategoryData);
    const [category, setCategory]=useState([]);

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          if(catalogueFormType==="New"){
            setViewOnly(false);
            resetValues();
            initializeValues();
            alertClose();
            //generateID();
        }else if(catalogueFormType==="Edit"){
          initializeValues();
          setViewOnly(false);
          alertClose();
        }else if(catalogueFormType==="View"){
          //console.log("Catalogue : ", catalogue);
          initializeValues();
          setViewOnly(true);
          alertClose();
        }
      }else{
        resetValues();
        validateLogin();
      }
        return () => {
            setCatalogueFormType("New");
            resetValues();
          }
      },[])  

    const handleCatalogueEdit=async(event)=>{
        event.preventDefault();
        try{
        alertClose();
        const validationResult=validateFields();
        if(validationResult==="passed"){
          setLoaded(false);
          const {catalogueID, title,author, addedDate,catalogueStatus, comments, category, publisher, selfNo, bookNo, specialAccess, removalDate}=catalogue;
          const todayDate=await generateDate();
          const catalogueid=catalogueID;
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const params={
            catalogueID: catalogueID, 
            title:title,
            selfNo:selfNo,
            bookNo:bookNo,
            author:author, 
            publisher:publisher, 
            addedDate:addedDate, 
            catalogueStatus:catalogueStatus, 
            removalDate:removalDate, 
            category:category,
            specialAccess:specialAccess?"Yes":"No",
            comments:comments,
            updatedBy:loggedUser.name, 
            updatedOn:todayDate
          };          //console.log("Params : ", params);
          await axios.patch(`${config.api.invokeURL}/catalogue/${catalogueid}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
              //console.log("response : ", response); 
              //await postVideoOnS3(videoid, posterFile);
              //await postPosterOnS3(videoid, posterFile);
              setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: catalogueid + " - Successfully Updated."
            }); 
            window.scrollTo(0,0);
            setLoaded(true);
            }).catch((error)=>{
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              window.scrollTo(0,0);
              setLoaded(true);
            })
        }
          }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        window.scrollTo(0,0);
        setLoaded(true);
        }
      }
    
      const generateSubscriberID=()=>{
        const current_datetime = new Date();
        //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
        //const tfName= fName.substring(0,2).toUpperCase();
        //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
        //const tlName= lName.substring(0,2).toUpperCase();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
        return accountNo;
    }
    
      const handleCatalogueNew=async(event)=>{
        event.preventDefault();
        try{
       // console.log("value : ", catalogue);
        alertClose();
        const validationResult=validateFields();
        if(validationResult==="passed"){
          setLoaded(false);
          
          const {catalogueID, title,author, addedDate,catalogueStatus, comments, category, publisher, selfNo, bookNo, specialAccess, lastUpdated }=catalogue;
          var catalogueid="";
          if(catalogueID===""){
            catalogueid= await generateSubscriberID();    
          }else{
            catalogueid= catalogueID;
          }
          var updatedOn="";
          if(lastUpdated===""){
            updatedOn= await generateDate();
          }else{
            updatedOn=lastUpdated;
          }
          //const catalogueid= await generateSubscriberID();
          //const todayDate=await generateDate();
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;

          const params={
            catalogueID: catalogueid, 
            title:title,
            selfNo:selfNo,
            bookNo:bookNo,
            author:author, 
            publisher:publisher, 
            addedDate:addedDate, 
            catalogueStatus:catalogueStatus, 
            removalDate:"NA", 
            category:category,
            specialAccess:specialAccess?"Yes":"No",
            comments:comments,
            updatedBy:loggedUser.name, 
            updatedOn:updatedOn
          };
          //console.log("Params : ", params);
          await axios.post(`${config.api.invokeURL}/catalogue/${catalogueid}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
              //console.log("Response : ", response);
              //await postOnS3(videoid, videoFile);
              //await postVideoOnS3(videoid, videoFile);
              //await postPosterOnS3(videoid, posterFile);

              setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: catalogueid + " - Successfully Registered."
            }); 
            window.scrollTo(0,0);
            resetValues();
            setLoaded(true);
            }).catch((error)=>{
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              window.scrollTo(0,0);
              setLoaded(true);
            })
            setLoaded(true);
        }
          }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        window.scrollTo(0,0);
        setLoaded(true);
        }
      }
    
      const resetValues=async()=>{
        //await initializeValues();
        const todayDate= generateDate();
        const catalogueid= await generateSubscriberID();
        //console.log("Category : ", category);
        await setCatalogue({...catalogue, catalogueID:catalogueid, title:'', author:'', publisher:'', comments:'', specialAccess:false, catalogueStatus:'Active',dateAdded:todayDate, lastUpdated:todayDate, lastUpdatedBy:'', removalDate:todayDate, bookNo:''});
      }
    
      const validateFields=()=>{
        const {title,authorName,addedDate, publisher, selfNo, category}=catalogue;      
            var returnValue="passed";
        if(title===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Book Title cannot be Empty"
          }); 
          returnValue="failed";
        }else  if(authorName===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Author Name cannot be Empty"
        }); 
        returnValue="failed";
      }else  if(selfNo===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Book Self cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(addedDate===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Date Added cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(publisher===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Publisher cannot be Empty"
      }); 
      returnValue="failed";
      }else  if(category===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Category cannot be Empty"
      }); 
      returnValue="failed";
      }else {
        returnValue="passed";
      }
      window.scrollTo(0,0);
      return returnValue;
      }

      const processCatalogueSelect=(tempCatid)=>{
        const tcat = catalogCategoryData.filter((item)=>item.category===tempCatid);
        setCatalogue({...catalogue, category:tempCatid,   
          selfNo:tcat[0].catid,
          specialAccess:tcat[0].specialAccess
        });
        //console.log("self no : ", tcat);
        setSelfNoData(tcat);
      }

      const initializeValues=async()=>{
        var uniqueArray = removeDuplicates("category",catalogCategoryData );
        var sortedcourseArray = uniqueArray.sort((a, b) => {return a.catid - b.catid;});
        await setCategory(sortedcourseArray);
        if(catalogueFormType==="New"){
        processCatalogueSelect(sortedcourseArray[0].category);
        }
      }

      const removeDuplicates=(tprop, coursesOffered)=> {
          var newArray = [];
          var lookupObject  = {};
          for(var i in coursesOffered) {
            lookupObject[coursesOffered[i][tprop]] = coursesOffered[i];
          }

          for(i in lookupObject) {
              newArray.push(lookupObject[i]);
          }
          return newArray;
      }


  
  return (
    <>
    <div>Add Catalogue</div>
    <div>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Catalogue</Breadcrumb.Item>
            <Breadcrumb.Item active>New</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
         {/*} <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
  />*/}
          </ButtonGroup>
        </div>
        </div>
     
      <CatalogueForm catalogue={catalogue} setCatalogue={setCatalogue} selfNoData={selfNoData} category={category} catalogueFormType={catalogueFormType}
      handleCatalogueEdit={handleCatalogueEdit} handleCatalogueNew={handleCatalogueNew} viewOnly= {viewOnly} processCatalogueSelect={processCatalogueSelect}/>
</div>
</>
)
}
