import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import {Input} from 'reactstrap';
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Breadcrumb } from '@themesberg/react-bootstrap';
import ProfileImage from "../../components/manageimage/ProfileImage";
import { phoneCode } from "../../ProData";
import CatalogRequestForm from "./CatalogRequestForm";
import { ProductContext } from '../../ProductProvider';
import SpecialAccessForm from "./SpecialAccessForm";
const config = require('../../config.json');


export  default function RegistrationForm(props) {
  const { subscriberDetails, setsubscriberDetails } = useContext(ProductContext);
  const { formType, setImageFile} = props;
  const [profileImage,setProfileImage]=useState('');
  const [viewOnly, setViewOnly] = useState(true);
  const [requestView, setRequestView] = useState(false);
  //const [materialsRequired, setMaterialsRequired]= useState({item:''});
  const componentRef = React.useRef(null);

  useEffect(()=>{
    if(subscriberDetails.avatar===undefined||subscriberDetails.avatar===""){
      setProfileImage("https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg");
    }else{
      const tapplicationNo=subscriberDetails.applicationNo;
      const tImageName=subscriberDetails.avatar;
      setProfileImage(`${config.s3.profileUrl}${tapplicationNo}${"/"}${tImageName}`);  
    }
    //console.log("form type :", formType);
    if(formType==="New"){
      setViewOnly(false);
      setRequestView(true);
    }else if(formType==="Edit"){
      setViewOnly(false);
    }else if(formType==="ViewReq"){
      setViewOnly(true);
      setRequestView(true);
    }else {
      setViewOnly(true);
      setRequestView(false);
    }
  },[])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  return (
    <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Member</Breadcrumb.Item>
            <Breadcrumb.Item active>Registration Form</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          </ButtonGroup>
        </div>
      </div>
     
      <div ref={componentRef}>
        {/*<ProfileImage formType={formType} profileImage={profileImage} setImageFile={setImageFile}/>*/}
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
        <Card border="light" className=" bg-white shadow-sm mb-4">

      <h3>Application Form</h3>
      <Card className="container">  
      {/*<ProfileImage fileName="test" setProfileImage={setProfileImage} profileImage={profileImage} setImageFile={setImageFile}/>*/}
      <ProfileImage formType={formType} profileImage={profileImage} setImageFile={setImageFile}/>

      <h3>Applicant Details:</h3>
      <Card className="container">     
          <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="title">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control required type="text" placeholder="Enter your Full name"           
                  disabled={viewOnly}
                  value={subscriberDetails.applicantName} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantName:event.target.value})}}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="title">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control required type="text" placeholder="Enter your Email Address" 
                                  disabled={viewOnly}
                  value={subscriberDetails.applicantEmailID} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantEmailID:event.target.value})}}/>
                </Form.Group>
              </Col>

          </Row>
          <Row>
          {/*<Col md={6} className="mb-3">
            <Form.Group id="contactNo">
              <Form.Label>Contact No</Form.Label>
              <Form.Control required type="text" placeholder="Enter your Contact Number" 
              value={applicant.applicantContactNo} onChange={(event)=>{setsubscriberDetails({...applicant, applicantContactNo:event.target.value})}}/>
            </Form.Group>
      </Col>*/}
      <Col sm={6} className="mb-3">
            <Form.Group id="areanumber">
              <Form.Label>Contact No</Form.Label>
              <Row>
              <Col sm={4}>
          <Input type="select" name="courseName"
                                value={subscriberDetails.applicantAreaCode} 
                                disabled={viewOnly}
                                onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantAreaCode:(event.target.value)})}}
                              >
                                {phoneCode.map(item => {
                              return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                            })}
                </Input>
          </Col>
          <Col sm={8}>
          <Form.Control required type="number" placeholder="Enter your Contact number" 
                          disabled={viewOnly}
              value={subscriberDetails.applicantContactNo} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantContactNo:event.target.value})}}/>
          </Col>
              </Row>               
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="title">
              <Form.Label>Address</Form.Label>
              <Form.Control required type="text" placeholder="Enter your Full Address" 
                              disabled={viewOnly}
              value={subscriberDetails.applicantAddress} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, applicantAddress:event.target.value})}}/>
            </Form.Group>
          </Col>

          </Row>
      </Card>
        <SpecialAccessForm formType={formType} />
        {requestView && <CatalogRequestForm formType={formType} />}
      </Card>
      </Card>
      </Form>
      </Card.Body>
      </Card>
    </div>
</div>
  )
}
