import React, { useState, useContext, useEffect, useRef } from 'react';
import { Col, Row, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import { Alert, Card, CardBody  } from "reactstrap";
import axios from "axios";
import { Auth } from 'aws-amplify';
//import { payOptionsData } from '../../ProData';
const config = require('../../config.json');

export default function SubscriptionForm(props) {
    const {formType} = props;
    const {loggedUser, generateOrderNo, alertDetails, setAlertDetails, alertClose, subscriberDetails} = useContext(ProductContext);
    const [paymentDetails, setPaymentDetails] = useState({paymentID:'', subscriberID:'', transactionDate:'', txStatus:'', paymentMode:'Cash', paymentPartner:'',
    gatewayName:'',currency:'INR', referenceId:'', orderAmount:config.package.amount, bankName:'', bankAddress:'', months:1, discountAmount:'', discountValue:'', gstCharges:'', 
    copies:'', packageAmount:config.package.amount, startDate:'', endDate:'', comments:''});
    const [loaded, setLoaded]=useState(true);    
    const [viewOnly, setViewOnly] = useState(true);
    const inputYears=useRef(null);
    const inputPaymentMode=useRef(null);
    const inputEndDate=useRef(null);
    const inputStartDate=useRef(null);
    const inputOrderAmount=useRef(null);
    const inputTransactionDate=useRef(null);
    const inputComments=useRef(null);
    const inputReferenceID=useRef(null);
    const inputCurrency=useRef(null);
    const inputBankName=useRef(null);

    useEffect(()=>{
        if(formType==="New"){
            resetValues();
            assignNewSubcriptionDates();
            setViewOnly(false);
        }else if (formType==="View"){
            setViewOnly(true);
            assignValues();
        }else if(formType==="Edit"){
            setViewOnly(false);
            assignValues();
        }
        alertClose();
    },[])

    function generateDate() {
        const current_datetime = new Date();
        var tempUserId = "";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value = "";
        date_month.length === 1 ? (month_value = month_value.concat(0, date_month)) : (month_value = date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value = "";
        date_date.length === 1 ? (date_value = date_value.toString().concat(0, date_date)) : (date_value = date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value = "";
        date_hours.length === 1 ? (hours_value = hours_value.toString().concat(0, date_hours)) : (hours_value = date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value = "";
        date_minutes.length === 1 ? (minutes_value = minutes_value.toString().concat(0, date_minutes)) : (minutes_value = date_minutes);
        const accountNo = tempUserId.concat(date_year, "-", month_value, "-", date_value);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;
        //console.log("acc : ", accountNo);
    }

    const resetValues=()=>{
        const todayDate=generateDate();
        setPaymentDetails({...paymentDetails, paymentID:'', subscriberID:'', transactionDate:todayDate, txStatus:'', paymentMode:'Cash', paymentPartner:'',
    gatewayName:'',currency:'INR', referenceId:'', orderAmount:config.package.amount, bankName:'', bankAddress:'', months:1, discountAmount:0, discountValue:0, gstCharges:0, 
    copies:'', packageAmount:config.package.amount, startDate:todayDate, endDate:todayDate});
    }

    const validateFields=()=>{
        var validateResult="PASS";
        if(paymentDetails.paymentMode==="Cheque"){
            if(paymentDetails.referenceId===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Cheque Number!"
                });  
                validateResult="FAIL";
            }else if(paymentDetails.bankName===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Bank Name!"
                });  
                validateResult="FAIL";
            }

        } else if(paymentDetails.paymentMode==="DD"){
            if(paymentDetails.referenceId===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter DD Number!"
                });  
                validateResult="FAIL";
            }else if(paymentDetails.bankName===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Bank Name!"
                });  
                validateResult="FAIL";
            }

        }else if(paymentDetails.paymentMode==="Online"){
            if(paymentDetails.referenceId===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Online Transaction Number!"
                });  
                validateResult="FAIL";
            }else if(paymentDetails.bankName===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message: "Enter Bank Name!"
                });  
                validateResult="FAIL";
            }

        }else{
            validateResult="PASS";

        }
        return validateResult;
    }

    const assignNewSubcriptionDates=()=>{
        //var tempDate= new Date(subscriberDetails.endDate);
        var tempDate= new Date();
        tempDate.setDate(tempDate.getDate() + 1);
        var date_date = ((tempDate.getDate()).toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_month = (tempDate.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        const tempYear = (tempDate.getFullYear()).toString();
        var tempRenew = "";
        const newStartDate = tempRenew.concat(tempYear,"-",month_value,"-",date_value);
        //const tempendDate= new Date(subscriberDetails.endDate);
        const tempendDate= new Date();
        var date_date1 = ((tempendDate.getDate()).toString());
        var date_value1="";
        date_date1.length===1?(date_value1=date_value1.toString().concat(0,date_date1)):(date_value1=date_date1);
        var date_month1 = (tempendDate.getMonth() + 2).toString();
        var month_value1="";
        date_month1.length===1? (month_value1=month_value1.concat(0,date_month1)):(month_value1=date_month1);
        const tempYear1 = (tempendDate.getFullYear()).toString();
        var tempRenew1 = "";
        const newEndDate = tempRenew1.concat(tempYear1,"-",month_value1,"-",date_value1);

        //setSubscribedAmount(amount);
        //setFinalAmount(amount);
        //setsubscriberDetails({...subscriberDetails, endDate:renewDate});
        setPaymentDetails({...paymentDetails, 
            months: 1,
            packageAmount:config.package.amount,
            orderAmount:config.package.amount,
            startDate:newStartDate,
            endDate:newEndDate,
            transactionDate:newStartDate
        });
    }

    const assignValues=()=>{
        setPaymentDetails(props.selectedItem);
        //console.log("props : ", props);
    }
    
    const handlePayment=async(event)=>{
        event.preventDefault();
        try{
            const validateResult=validateFields();
            //console.log("validation : ", validateResult);
            if(validateResult==="PASS"){
            setLoaded(false);
            const tdate=generateDate();
            const subscriptionid=generateOrderNo();
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;  
            const {transactionDate, paymentMode,currency, referenceId, orderAmount, bankName, bankAddress, months, discountAmount, discountValue, gstCharges, packageAmount, startDate, endDate, comments}   = paymentDetails;         
            const params={
                subscriptionID:subscriptionid,
                paymentMode:paymentMode,
                paymentPartner:paymentMode,
                orderAmount:orderAmount,
                txStatus:"TXN_SUCCESS",
                applicationNo:subscriberDetails.applicationNo,
                transactionDate:transactionDate,
                referenceId:referenceId,
                currency:currency,
                discountAmount:discountAmount,
                discountValue:discountValue,
                bankName:bankName,
                packageAmount:packageAmount,
                updatedBy:loggedUser.name,
                updatedOn:tdate,
                endDate:endDate,
                startDate:startDate,
                gstCharges:gstCharges,
                months:months,
                bankAddress:bankAddress,
                comments:comments
            };
            //console.log("Params : ", params);
            await axios.post(`${config.api.invokeURL}/subscription/${subscriptionid}`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                  //console.log("response : ", response); 
                 //fetchCurrentYearDetails(selectedBatch);
                 setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: subscriptionid +  " - Payment successfully processed!"
                }); 
                resetValues();
                window.scrollTo(0,0); 
                setLoaded(true);
                //newToggle();
                }).catch((error)=>{
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0); 
                  setLoaded(true);
                })
            }

    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });  
        setLoaded(true);      
    }
    }

    const handlePaymentEdit=async(event)=>{
        event.preventDefault();
        try{
            const validateResult=validateFields();
            //console.log("validation : ", validateResult);
            if(validateResult==="PASS"){
            setLoaded(false);
            const tdate=generateDate();
            //const paymentid=generateOrderNo();
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;  
            const {subscriptionID, transactionDate, txStatus, paymentMode,currency, referenceId, orderAmount, bankName, bankAddress, months, discountAmount, discountValue, gstCharges, packageAmount, startDate, endDate, comments}   = paymentDetails;         
            const params={
                subscriptionID:subscriptionID,
                paymentMode:paymentMode,
                paymentPartner:paymentMode,
                orderAmount:orderAmount,
                txStatus:txStatus,
                applicationNo:subscriberDetails.applicationNo,
                transactionDate:transactionDate,
                referenceId:referenceId,
                currency:currency,
                discountAmount:discountAmount,
                discountValue:discountValue,
                bankName:bankName,
                packageAmount:packageAmount,
                updatedBy:loggedUser.name,
                updatedOn:tdate,
                endDate:endDate,
                startDate:startDate,
                gstCharges:gstCharges,
                months:months,
                bankAddress:bankAddress,
                comments:comments
            };
            //console.log("Params : ", params);
            const subscriptionid=subscriptionID;
            //await axios.post(`${config.api.invokeUrl}/payments/${paymentid}`, params,{
            await axios.patch(`${config.api.invokeURL}/subscription/${subscriptionid}`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                  //console.log("response : ", response); 
                 //fetchCurrentYearDetails(selectedBatch);
                 setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: subscriptionid + " - Payment successfully Updated!"
                }); 
                resetValues();
                window.scrollTo(0,0); 
                setLoaded(true);
                props.newToggle();
                }).catch((error)=>{
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0); 
                  setLoaded(true);
                })
            }

    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });  
        setLoaded(true);      
    }
    }

    const handleYearChange =(tyears)=>{
        var amount=config.package.amount*tyears;
        //console.log("Amount : ", amount);
        const tempDate= new Date(paymentDetails.startDate);
        const tempDate1= new Date(paymentDetails.startDate);
        const tempMonth=tempDate1.getMonth()+parseInt(tyears);
        //console.log("Temp Month : ", tempMonth);
        var newDate = new Date(tempDate1.setMonth(tempMonth));
        //console.log("New Date :", newDate);

        var date_month = (newDate.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (newDate.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var tempRenew = "";
        const tempYear = (newDate.getFullYear()).toString();
        const renewDate = tempRenew.concat(tempYear,"-",month_value,"-",date_value);
        
        //setSubscribedAmount(amount);
        //setFinalAmount(amount);
        //setsubscriberDetails({...subscriberDetails, endDate:renewDate});
        //console.log("Renew Date :", renewDate);
        setPaymentDetails({...paymentDetails, 
            months: tyears,
            packageAmount:config.package.amount,
            orderAmount:amount,
            //startDate:subscriberDetails.startDate,
            endDate:renewDate
        });
    }

    const handleStartDateChange =(tdate)=>{
        var amount=config.package.amount*paymentDetails.months;
        //console.log("Amount : ", amount);
        const tempDate= new Date(tdate);
        const tempYear = (tempDate.getFullYear()).toString();
        var date_month = (tempDate.getMonth() + 1+parseInt(paymentDetails.months)).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (tempDate.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var tempRenew = "";
        const renewDate = tempRenew.concat(tempYear,"-",month_value,"-",date_value);
        
        //setSubscribedAmount(amount);
        //setFinalAmount(amount);
        //setsubscriberDetails({...subscriberDetails, endDate:renewDate});
        setPaymentDetails({...paymentDetails, 
            //months: tyears,
            packageAmount:config.package.amount,
            orderAmount:amount,
            startDate:tdate,
            endDate:renewDate
        });
    }

    const handleEventKey=(event, item)=>{
        //console.log("event : ", event);
        if(event.key === 'Enter'){
            if(item==="1"){
                inputEndDate.current.focus();
            }else if(item==="2"){
                inputPaymentMode.current.focus();
            }else if(item==="3"){
                inputCurrency.current.focus();
            }else if(item==="4"){
                inputYears.current.focus();
            }else if(item==="5"){
                inputOrderAmount.current.focus();
            }else if(item==="8"){
                inputTransactionDate.current.focus();
            }else if(item==="9"){
                inputComments.current.focus();
            }
        }
    }

  return (
    <div>  
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>
    <div className="table-settings mb-4">
    {formType==="View" || formType==="Edit"?null : <><Row>
    <Col md={6} className="mb-3">
    <Form.Label>Name : {subscriberDetails.applicantName} </Form.Label>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>ID : {subscriberDetails.applicationNo} </Form.Label>
    </Col>
    </Row>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Email ID : {subscriberDetails.applicantEmailID} </Form.Label>
    </Col>
    <Col md={6} className="mb-3">
    <Form.Label>Status : {subscriberDetails.applicationStatus} </Form.Label>
    </Col>
  </Row></>}
 {/*} <Card >
    <CardBody>
      <Form.Label>Current Subscription Details :  </Form.Label>
  <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Start Date :  </Form.Label>
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="date" disabled={viewOnly} value={subscriberDetails.startDate} />
        </InputGroup>
        </Col>
        <Col md={6} className="mb-3">
        <Form.Label>End Date :  </Form.Label>
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="date" disabled={viewOnly} value={subscriberDetails.endDate} />
        </InputGroup>
        </Col>    
    </Row>   
    </CardBody>
  </Card>*/}
    
    <div  style={{paddingTop:"10px"}}>
    <Card>
    <CardBody>
    <Form.Label>New Subscription Details :  </Form.Label>
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Start Date :  </Form.Label>
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="date" autoFocus value={paymentDetails.startDate} disabled={viewOnly}
          onChange={(event)=>{handleStartDateChange(event.target.value)}}
          ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"1")}} />
        </InputGroup>
        </Col>
        <Col md={6} className="mb-3">
        <Form.Label>End Date :  </Form.Label>
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="date" value={paymentDetails.endDate} disabled
          onChange={(event)=>{setPaymentDetails({...paymentDetails, endDate: event.target.value})}}
          ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"2")}} />
        </InputGroup>
        </Col>    
    </Row>
    <Row>
    <Col md={4} className="mb-3">
    <Form.Label>Mode of Payment:  </Form.Label>
     <Form.Group id="searchstud">
           <Form.Select defaultValue="Cash" value={paymentDetails.paymentMode} disabled={viewOnly}
           onChange={(event)=>{setPaymentDetails({...paymentDetails, paymentMode: event.target.value})}}
           ref={inputPaymentMode} onKeyDown={event=>{handleEventKey(event,"3")}} >
             <option value="Cash">Cash</option>
             <option value="Cheque">Cheque</option>
             <option value="Bank Transfer">Bank Transfer</option>
             <option value="Demand Draft">Demand Draft</option>
             <option value="Donation">Donation</option>
             <option value="Money Order">Money Order</option>
             <option value="Online">Online</option>

           </Form.Select>
         </Form.Group>
        </Col>
        <Col md={4} className="mb-3">
    <Form.Label>Currency:  </Form.Label>
    <Form.Group id="currency">
           
    <Form.Select defaultValue="INR" value={paymentDetails.currency} disabled={viewOnly}
           onChange={(event)=>{setPaymentDetails({...paymentDetails, currency: event.target.value})}}
           ref={inputCurrency} onKeyDown={event=>{handleEventKey(event,"4")}} >
             <option value="INR">INR</option>
             <option value="USD">USD</option>
           </Form.Select>
         </Form.Group>
        </Col>
    <Col md={4} className="mb-3">
    <Form.Label>Months:  </Form.Label>
    <Form.Group id="searchstud">        
           <Form.Select
           value={paymentDetails.months}
           disabled={viewOnly} ref={inputYears} onKeyDown={event=>{handleEventKey(event,"5")}}
           onChange={(event)=>{handleYearChange(event.target.value)}}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
           </Form.Select>
         </Form.Group>
        </Col>
    </Row> 
    {paymentDetails.paymentMode==="Cash"?null: <Row>
    <Col md={6} className="mb-3">
    <Form.Label>{paymentDetails.paymentMode} No:  </Form.Label>
     <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Cheque / DD / Transaction No" value={paymentDetails.referenceId} 
          disabled={viewOnly} ref={inputReferenceID} onKeyDown={event=>{handleEventKey(event,"6")}}
          onChange={(event)=>{setPaymentDetails({...paymentDetails, referenceId: event.target.value})}}/>
        </InputGroup>
    </Col>
    <Col md={6} >
      <Form.Label>Bank Name:  </Form.Label>
    
      <InputGroup>
          <InputGroup.Text>
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Bank Name" value={paymentDetails.bankName} disabled={viewOnly}
            ref={inputBankName} onKeyDown={event=>{handleEventKey(event,"7")}}
          onChange={(event)=>{setPaymentDetails({...paymentDetails, bankName: event.target.value})}}/>
        </InputGroup>
      </Col>
    </Row>}
    <Row>
    <Col md={6} className="mb-3">
    <Form.Label>Amount to be Paid :  </Form.Label>
    <InputGroup>
         
    <Form.Control type="number" value={paymentDetails.orderAmount} disabled={viewOnly} 
    onKeyDown={event=>{handleEventKey(event,"8")}} ref={inputOrderAmount}/>
          
        </InputGroup>
        </Col>
      <Col  md={6} >
      <Form.Label>Date of Payment :  </Form.Label>
      <InputGroup>
         
          <Form.Control type="date" placeholder="Paid on" value={paymentDetails.transactionDate} disabled={viewOnly}
          onChange={(event)=>{setPaymentDetails({...paymentDetails, transactionDate: event.target.value})}}
          onKeyDown={event=>{handleEventKey(event,"9")}} ref={inputTransactionDate}/>
        </InputGroup> 
      </Col>
      
    </Row> 
    <Row>
    <Form.Label>Comments:  </Form.Label>
    
    <InputGroup>
        <InputGroup.Text>
        </InputGroup.Text>
        <Form.Control type="text" placeholder="Comments..." value={paymentDetails.comments} disabled={viewOnly}
        onChange={(event)=>{setPaymentDetails({...paymentDetails, comments: event.target.value})}}
        ref={inputComments} onKeyDown={event=>{handleEventKey(event,"10")}} />
      </InputGroup>

    </Row>
    </CardBody>
    </Card>
    </div>
    
   
   {/*} <Row>
    <Col md={6} className="mb-3">
    <Form.Check type="checkbox">
        <FormCheck.Input id="defaultCheck5" className="me-2" checked={concessionEnabled} onChange={(event)=>{handleConcessionCheck(event)}}/>
        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Process Concession</FormCheck.Label>
    </Form.Check>
    </Col>
    {concessionEnabled?
    <Col md={6} className="mb-3">
    <Form.Label>Concession Amount :  </Form.Label>
    <InputGroup>
        <Form.Control type="number" value={concessionValue} onChange={(event)=>{handleConcessionUpdate(event)}} />
    </InputGroup>
    </Col>:null}
    </Row>*/}
    {/*<Row>
    <Col md={6} className="mb-3">
    <Form.Check type="checkbox">
        <FormCheck.Input id="donationCheck5" className="me-2" checked={donationEnabled} onChange={(event)=>{handleDonationCheck(event)}}/>
        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Process Donation</FormCheck.Label>
    </Form.Check>
    </Col>
    {donationEnabled?
    <Col md={6} className="mb-3">
    <Form.Label>Donation Amount :  </Form.Label>
    <InputGroup>
        <Form.Control type="number" value={donationValue} onChange={(event)=>{handleDonationUpdate(event)}} />
    </InputGroup>
    </Col>:null}
    </Row>*/}
    <Row>
    {formType==="New"?<div className="text-center mb-2 mb-md-0">
    <Button variant="primary" type="submit" onClick={(event)=>{handlePayment(event)}}>Save</Button>
      </div>:formType==="Edit"?<div className="text-center mb-2 mb-md-0">
    <Button variant="primary" type="submit" onClick={(event)=>{handlePaymentEdit(event)}}>Update</Button>
      </div>:null}
    </Row>
  </div></div>
  )
}
