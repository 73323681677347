import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHome, faSearch, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from '@themesberg/react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { downloadPaymentHeaders } from "../../ProData";
import {Helmet} from 'react-helmet-async';
import RegistrationForm from "../Registrations/RegistrationForm";

const config = require('../../config.json');

export default function SearchCatalogueRequest() {
  const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin, setsubscriberDetails, generateDate, subscriberDetails} = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('SubscriberID');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  //const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  //const [selectedItem, setSelectedItem] = useState({});
  //const [action, setAction] = useState('View');
  const componentRef = React.useRef(null);
  //const componentModelRef = React.useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      //console.log("Subscribtion Details : ", subscriberDetails);
      //processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleApplicationView=async(event, item)=>{
    event.preventDefault();
    //console.log("Item : ", item);
    try{
      setLoaded(false);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const fetchOption="ApplicationID";
      await axios.get(`${config.api.invokeURL}/dashboard/search?fetchOption=${fetchOption}&fetchKey=${item.applicationNo}`,{
        headers: {
          Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
      //console.log("Response : ", response.data);
      if(item.avatar===undefined){
        item.avatar="";
      }
      const {applicantName,applicationStatus,applicantEmailID,applicantAreaCode, applicantAddress, applicantContactNo, specialAccess, startDate, endDate, registrationDate, applicationNo }=response.data[0];
      item.applicantName=applicantName;
      item.applicationStatus=applicationStatus;
      item.applicantEmailID=applicantEmailID;
      item.applicantAreaCode=applicantAreaCode;
      item.applicantAddress=applicantAddress;
      item.specialAccess=specialAccess;
      item.applicantContactNo=applicantContactNo;
      item.startDate=startDate;
      item.endDate=endDate;
      item.registrationDate=registrationDate;
      setsubscriberDetails(item);
      newToggle();
      //setApplicationDetails(response.data);
        setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: applicationNo + " successfully fetched!" 
        });  
        setLoaded(true);
    }).catch((error)=>{
        //console.log(error);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        //setDispMsg(error.message);
    })
    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
    }
}


  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const handleCatalogueAction=async(event, tstatus)=>{
    event.preventDefault();
    try{
        const tDate= await generateDate();
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const params={
            requestID:subscriberDetails.requestID,
            requestStatus:tstatus,  
            updatedBy:loggedUser.name,
            updatedOn:tDate,
        }
        //console.log("Params : ", params);
        await axios.patch(`${config.api.invokeURL}/catalogue_request`, params,{
            headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
            }})
            .then(async(response)=>{
            //  console.log("Response : ", response);
            handleSearch();
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: subscriberDetails.requestID + " - Successfully Updated!"
            }); 
            newToggle();
            window.scrollTo(0,0);
            setLoaded(true);            
            })
            .catch((error)=>{
                setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);
            })
    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
    }
}

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  

      }else{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;  
      await axios.get(`${config.api.invokeURL}/catalogue_request/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("response : ", response.data);
        if(response.data.length>0)
        {
            //console.log("order status")
            setSearchResults(response.data);
        }
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: response.data.length + " - Catalogue records found!"
      });  
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  return (
    <>
    <Helmet>
      <title>Catalogue Search - Admin Office - Archives</title>
      <meta name="description" content="Catalogue Search - Admin Office - Archives" />
      <meta name="keywords" content="Catalogue Search - Admin Office - Archives" /> 
    </Helmet>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {/*Payment*/}
        <Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
            <ModalHeader toggle={newToggle}>
            Catalogue Request - {subscriberDetails.requestID}
            </ModalHeader>
            <ModalBody>    
            <div>
            <RegistrationForm formType={"View"}/> 
            </div>
            </ModalBody>
            <ModalFooter>
            {subscriberDetails.requestStatus==="Pending"?<>
            <Button onClick={event=>handleCatalogueAction(event, "Approve")}>
            Approve
            </Button>
            <Button onClick={event=>handleCatalogueAction(event, "Declined")}>
                Declined
            </Button>
            </>:null}
            <Button onClick={newToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Catalogue</Breadcrumb.Item>
            <Breadcrumb.Item>Request</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Catalogue Request Search</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadPaymentHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="SubscriberID" value={searchTopic} onChange={(event)=>{setSearchTopic(event.target.value)}}>
                <option value="SubscriberID">Application No</option>
                <option value="RequestID">Request ID</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>
      <Table ref={componentRef} hover responsive>
        <thead>
            <tr>
            <th>#</th>
            <th>Request ID</th>
            <th>Subscriber ID</th>
            <th>Requested Date</th>
            <th>Project Title</th>
            <th>Status</th>
            <th>Action</th>
            </tr>
        </thead>
        <tbody>
        {searchResults.map((item, index)=>
        <tr key={index}>
        <th scope="row">{index+1}</th>
        <td>{item.requestID}</td>
        <td>{item.applicationNo}</td>
        <td>{item.requestDate}</td>
        <td>{item.researchProjectTitle}</td>
        <td>{item.requestStatus==="Pending Approval"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.requestStatus}</p>:
        item.requestStatus==="Approved"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.requestStatus}</p>:
        <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.requestStatus}</p>}</td>
        <td>
        <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
            <FontAwesomeIcon icon={faEye} className="me-2" /> View Request
            </Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        </td>
        </tr>)}
        </tbody>
      </Table>
    </>
  )
}
