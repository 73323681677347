import React, {useEffect, useContext} from 'react';
import { Row, Col, Card} from 'reactstrap';
//import CoursePaymentInsights from './CoursePaymentInsights'
import RegistrationInsights from './RegistrationInsights'
import { ProductContext } from '../../ProductProvider';
//import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import RegistrationActiveInsights from './RegistrationActiveInsights';
import RegistrationInActiveInsights from './RegistrationInActiveInsights';
import RegistrationCount from './RegistrationCount';
import CatalogueInsights from './catalogueDashboard/CatalogueInsights';

export default function InsightsOverview() {
  //let history=useHistory();
  const { loggedUser, validateLogin, alertClose} = useContext(ProductContext);

  useEffect(()=>{
    alertClose();
    if(!loggedUser.isAuthenticated){
      validateLogin();
      /*const validationResult=validateLogin();
      if(validationResult==="Failed"){
        history.push(Routes.Signin.path);
      }else if(validationResult==="NOT Authorized"){
        history.push(Routes.Signin.path);
      }else{
        history.push(Routes.DashboardOverview.path);
      }*/
      }
  },[loggedUser])

  return (
      <>
          <Helmet>
      <title>Insights and Analytics - Admin Office - Archives</title>
      <meta name="description" content="Insights and Analytics - Admin Office - Analytics" />
      <meta name="keywords" content="Insights and Analytics - Admin Office - Analytics" /> 
    </Helmet>

          <div className='container'>
            <h1>Insights Overview</h1>
            <Row>
            <Col md={12}>
              <Card style={{padding:20, height:250, alignItems:'center'}}>
                <h3><u>User Account Status</u></h3>
                <RegistrationCount />
              </Card>
            </Col>
            <Col md={12}>
            <Card style={{padding:20, height:300, alignItems:'center', alignContent:'center', alignSelf:'center'}}>
                <h3><u>Catalogue Material Status</u></h3>
                <CatalogueInsights />
              </Card>
            </Col>

           {/*} <Col md={12}>
                <RegistrationInsights />
            </Col>
            <Col md={6}>
                <RegistrationActiveInsights />
            </Col>
            <Col md={6}>
                <RegistrationInActiveInsights />
            </Col>
            {/*<Col md={6}>
                <CoursePaymentInsights />
  </Col>*/}
          </Row> 
  </div>
      </>
  )
}
