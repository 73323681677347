import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { faEye, faEllipsisH, faCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { Button} from '@themesberg/react-bootstrap';
import RegistrationForm from './RegistrationForm';
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import { Auth } from 'aws-amplify';
const config = require('../../config.json');

export default function ListRegistrationStatus(props) {
    const {applicationDetails, setApplicationDetails, status} = props;
    const { setsubscriberDetails, loggedUser, alertClose, alertDetails, setAlertDetails, generateDate } = useContext(ProductContext);

    const [loaded, setLoaded] = useState(false);
    const [collapsed, setCollapsed] = useState(true);


    useEffect(()=>{
        //console.log("props : " , props);
        alertClose();
        fetchApplicationDetails();
    },[])

    const toggle = () => {
      //   console.log("clicked");
      setCollapsed(!collapsed);
    }

    const fetchApplicationDetails=async()=>{
        try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const clientID="ARCHIVE";
            const applicationStatus=status;
            await axios.get(`${config.api.invokeURL}/dashboard/registration?clientID=${clientID}&applicationStatus=${applicationStatus}`,{
                headers: {
                  Authorization: access_token,
                  'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
              setApplicationDetails(response.data);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: response.data.length + " - " + status
                });  
                //console.log(response);
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                //setDispMsg(error.message);
            })
        
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
        }
    }

    const handleApplicationView=async(event, item)=>{
        event.preventDefault();
        console.log("Item : ", item);
        try{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const fetchOption="SubscriberID";
          await axios.get(`${config.api.invokeURL}/catalogue_request/search?fetchOption=${fetchOption}&fetchKey=${item.applicationNo}`,{
            headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
          console.log("Response : ", response.data);
          if(item.avatar===undefined){
            item.avatar="";
          }
          const {guideAddress,guideContactNo,guideDesignation,guideEmailID, guideName, institutionAddress, institutionContactNo, institutionEmailID, institutionName, materialRequired,researchProjectTitle }=response.data[0];
          item.guideAddress=guideAddress;
          item.guideContactNo=guideContactNo;
          item.guideDesignation=guideDesignation;
          item.guideEmailID=guideEmailID;
          item.guideName=guideName;
          item.institutionAddress=institutionAddress;
          item.institutionContactNo=institutionContactNo;
          item.institutionEmailID=institutionEmailID;
          item.institutionName=institutionName;
          item.materialRequired=materialRequired;
          item.researchProjectTitle=researchProjectTitle;
          console.log("ITem : ", item);
          setsubscriberDetails(item);
          toggle();
          //setApplicationDetails(response.data);
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: response.data.length + " - " + status
            });  
            setLoaded(true);
        }).catch((error)=>{
            //console.log(error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            //setDispMsg(error.message);
        })
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
        }
    }
    
    const handleApplicationUpdate=async(event, item, tStatus)=>{
        event.preventDefault();
      //  console.log("Item : ", item);
        try{
          setLoaded(false);
            const tDate= await generateDate();
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const params={
                applicationNo:item.applicationNo,
                applicantName:item.applicantName,  
                applicationDate:item.applicationDate, 
                applicationStatus:tStatus,
                applicantContactNo:item.applicantContactNo,
                applicantEmailID:item.applicantEmailID,
                applicantAreaCode:item.applicantAreaCode,
                updatedBy:loggedUser.name,
                updatedOn:tDate,
            }
            //console.log("Params : ", params);
            await axios.patch(`${config.api.invokeURL}/dashboard/registration/registration_update`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                //  console.log("Response : ", response);
                fetchApplicationDetails();
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Successfully Updated"
                }); 
                window.scrollTo(0,0);
                  setLoaded(true);            
                })
                .catch((error)=>{
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);
                })
          
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);
        }
    }

  return (
      <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {/*View*/}
        <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', width:'800%'}}>
                    <ModalHeader toggle={toggle}>
                    </ModalHeader>
                    <ModalBody>    
                        <RegistrationForm formType={"View"}/> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Application No</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Date Applied</th>
               {status==="Pending Approval"||status==="Approved"? <th>Action</th>:null}
                </tr>
            </thead>
            <tbody>
            {applicationDetails.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.applicationNo}</td>
            <td>{item.applicantName}</td>
            <td>{item.applicantAreaCode}-{item.applicantContactNo}</td>
            <td>{item.applicantEmailID}</td>
            <td>{item.applicationDate}</td>
           {status==="Pending Approval"? <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item   onClick={(event)=>{handleApplicationView(event, item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleApplicationUpdate(event, item,"Pending Payment")}}>
                <FontAwesomeIcon icon={faCheck} className="me-2" /> Approve
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}>
                <FontAwesomeIcon icon={faCancel} className="me-2" /> Deny
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>:status==="Approved"? <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>:null}
            </tr>)}
            </tbody>
        </Table>
    </>
  )
}
