import React, { useContext, useEffect} from "react";
import { Card, CardBody } from 'reactstrap';
import logo from '../assets/arlogo.png';
import { ProductContext } from '../ProductProvider';
import {Helmet} from 'react-helmet-async';

export default function WelcomeDash() {

  const { alertClose, loggedUser, validateLogin } = useContext(ProductContext);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
    }else{
      validateLogin();
    }
    return()=>{
      alertClose();
    }
  },[loggedUser])

  return (
    <div className='container text-center'>
    <Helmet>
      <title>Admin Office - Archives</title>
      <meta name="description" content="Admin Office - Archives" />
      <meta name="keywords" content="Admin Office - Archives" /> 
    </Helmet>

        <Card>
            <CardBody>
                <h3 className='text-info' style={{fontFamily:'sans-serif'}}>Welcome to ARCHIVE'S</h3>
                <img src={logo} alt='logo' width="50%" height="40%"/>
                <h1>Admin Portal</h1>
            </CardBody>
        </Card>
    </div>
  )
}
