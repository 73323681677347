import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEllipsisH, faSearch, faPlusCircle, faExchange } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Table, Dropdown } from '@themesberg/react-bootstrap';
//import {Input } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import { ProductContext } from '../../ProductProvider';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
//import { courseMaterialData } from '../../ProData';
import Preloader from "../../components/Preloader";
//import ProgressBarStatus from "../../components/ProgresssBarStatus";
import EmailUpdateModal from "./EmailUpdateModal";
import {Helmet} from 'react-helmet-async';

//import { TransactionsTable } from "../../components/Tables";
const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneRegex=/^\+[1-9]{1}[0-9]{3,14}$/;
const dobRegex=/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

export default () => {
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin } = useContext(ProductContext);
  const [batchYear, setBatchYear] = useState([]);
  const [selectedBatch, setSelectedBatch]=useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loaded, setLoaded]=useState(true);
  const componentRef = React.useRef(null);
  const [updateEmailCollapsed, setUpdateEmailCollapsed]=useState(true);
  const [selectedEmail, setSelectedEmail]=useState('');
  const [selectedRegistrationNo, setSelectedRegistrationNo]=useState('');
  const [registeredResults, setRegisteredResults]=useState([]);
  const [missingResults, setMissingResults]=useState([]);
  const [searchTopic, setSearchTopic] = useState('ApplicationID');
  const [searchValue, setSearchValue] = useState('');

  //const [collapsed, setCollapsed]=useState(true);
  //const [historyCollapsed, setHistoryCollapsed]=useState(true);

  //const [selectedItem, setSelectedItem] = useState('');
  //const [courseOpted, setCourseOpted] = useState('');
  //const [payMsg, setPayMsg] = useState('');
  //const [currentPayStatus, setCurrentPayStatus] = useState('');
  //const [payAmount, setPayAmount] = useState('');
  //const [enrollCourse, setEnrollCourse]=useState({});
  //const [feeBreakUp, setFeeBreakUp] =useState([]); 
  //const [searchParam] = useState(["firstName","applicationNo"]);
  
  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }
  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    setSelectedBatch(date_year);
    //fetchCurrentYearDetails(date_year);
  }

  const fetchCurrentYearDetails=async()=>{
   // event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  
      }else{
        setLoaded(false);
        //const searchTopic="BatchFeeStatus";
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken; 
      //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        await axios.get(`${config.api.invokeURL}/dashboard/accounts/userslist?searchValue=${searchValue}&searchTopic=${searchTopic}`, {  
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("Response : ", response);
        if(response.data.length>0)
        {
       //     console.log("order status")
            //const tTempResponse=response.data;
         //   initializeValues(response.data);
           // var courseCompleted=0;
          /*  for(const i in tTempResponse){
                if(tTempResponse[i].assessmentResult==="PASS"){
                    //console.log("value : ", tTempResponse[i].registrationNo);
                    courseCompleted=courseCompleted+1;
                }
            }*/
              //const tProgressValue=((courseCompleted/tTempResponse.length)*100).toFixed(2);
              //setProgressValue(tProgressValue);
              
            setSearchResults(response.data[0]);
            setRegisteredResults(response.data[1]);
            processResults(response.data[0], response.data[1]);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: selectedBatch +  " loaded Successfully!"
          });  

        }
        setLoaded(true);

    }).catch((error)=>{
      console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  const processResults=(taccounts,tregister)=>{
   // console.log("Register : ", tregister);
   // console.log("Users : ", taccounts);
    var ttaccounts=[];
    var tttaccounts=[];
    for (const i in taccounts){
      var tobject={};
      tobject.regNo=taccounts[i].Attributes[0]['Value'];
      ttaccounts.push(taccounts[i].Attributes[0]['Value']);
      tttaccounts.push(tobject);
    }
   // console.log("TTaccounts : ", tttaccounts);
   /* var absent = tregister.filter(object1 => {
      return !taccounts.some(object2 => {
        console.log("Reg : ", object2.Attributes[0]['Value']);
        return object1.registrationNo === object2.Attributes[0]['Value'];
      });
    });*/
    //var objectmissing = tregister.filter((item)=>{taccounts.includes(account=>{ item.registrationNo!=account.Attributes[0]['Value']})});
    //var absent1 = tregister.filter((item)=>{tttaccounts.includes(account=>{ item.registrationNo!=account})});

    const results = tregister.filter(({ registrationNo: id1 }) => !tttaccounts.some(( {regNo: id2 }) => id2 === id1));

    //console.log("missing values : ", results);
    setMissingResults(results);

  }

  const fetchApplicationDetails=async(event, searchValue)=>{
    event.preventDefault();
    fetchCurrentYearDetails(searchValue);
  }

  const handleSearchTopicSelection=(event)=>{
    if(event.target.value==="SpecialAccess"){
      setSearchValue("Yes");
    }else if(event.target.value==="Status"){
      setSearchValue("Active");
    }else if(event.target.value==="JoiningYear"){
      setSearchValue(batchYear[0]);
    }else{
      setSearchValue('')
    }
    setSearchTopic(event.target.value)
  }


  const emailToggle = () => {
    //   console.log("clicked");
    setUpdateEmailCollapsed(!updateEmailCollapsed);
  }

  const handleEmailUpdateView =(event, item)=>{
    event.preventDefault();
    //console.log("item : ", item['Attributes'][1]['Value']);
   // setProfileDetails(item);
    setSelectedEmail(item['Attributes'][1]['Value']);
    setSelectedRegistrationNo(item['Attributes'][0]['Value']);
    setUpdateEmailCollapsed(!updateEmailCollapsed);
  }

  const handleCreateAccount=async(event, item)=>{
    event.preventDefault();
    try{
      var tphone="";
      console.log("Item : ", item);
      if(item.applicantAreaCode!==""&& item.applicantContactNo!==""){
      tphone=item.applicantAreaCode.toString()+item.applicantContactNo.toString();
      }
      if(item.registrationDate===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Registration Date needs to be Updated first. Go to Member Profile and Update the Registration Date"
      });        
      }else if(!item.registrationDate.match(dobRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Registration Date needs to be Updated first. Go to Member Profile and Update the Registration Date"
      });        
      }else if(item.applicantEmailID===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Email Address needs to be Updated first. Go to Member Profile and Update the Email Address"
      });        
      }else if(!item.applicantEmailID.match(validRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Email address is not in right format!"
      }); 
      }else if(!tphone.match(phoneRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Area Code and Phone Number is not in right format!"
      }); 
      }else if(item.gender===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Gender Status needs to be Updated first. Go to Student Profile and Update the Gender"
      });        
      }else if(item.areaCode==="" || item.mobileNumber===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Area Code and Mobile Number needs to be Updated first. Go to Member Profile and Update the Area Code and Mobile Number"
      });        
      }else{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken; 
          const {applicationNo, applicantEmailID, registrationDate, applicantAreaCode, applicantContactNo, applicantName} = item;
          const params={
            applicationNo:applicationNo,
            gender: 'NA',
            applicantEmailID:applicantEmailID,
            registrationDate:registrationDate,
            areaCode:applicantAreaCode,
            mobileNumber:applicantContactNo,
            applicantName:applicantName
          }
        //console.log("Params : ", params);
        //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
          await axios.post(`${config.api.invokeURL}/dashboard/accounts/createnew`,params, {  
          headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
        // console.log("Response : ", response);
         if(response.status===215){
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: response.data
          });  
          }else if(response.status===202){
          await fetchCurrentYearDetails();
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Login Account Created Successfully!"
          });  
          }
          setLoaded(true)
      }).catch((error)=>{
       // console.log("Error : ", error);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      setLoaded(true);
      window.scrollTo(0,0);
    })
  }
    }catch(error){
     // console.log("Error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    window.scrollTo(0,0);
    }
    window.scrollTo(0,0);
  }

  const handleRecreateAccount=async(event, item)=>{
    event.preventDefault();
    try{
      //console.log("Item : ", item);
      //const tphone=item.areaCode.toString()+item.mobileNumber.toString();
      //console.log("Tphone : ", tphone);
      /*if(item.dateOfBirth===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Date of Birth needs to be Updated first. Go to Student Profile and Update the Date of Birth"
      });        
      }else if(!item.dateOfBirth.match(dobRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Date of Birth needs to be Updated first. Go to Student Profile and Update the Date of Birth"
      });        
      }else if(item.emailID===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Email Address needs to be Updated first. Go to Student Profile and Update the Email Address"
      });        
      }else if(!item.emailID.match(validRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Email address is not in right format!"
      }); 
      }else if(!tphone.match(phoneRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Area Code and Phone Number is not in right format!"
      }); 
      }else if(item.gender===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Gender Status needs to be Updated first. Go to Student Profile and Update the Gender"
      });        
      }else if(item.areaCode==="" || item.mobileNumber===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Area Code and Mobile Number needs to be Updated first. Go to Student Profile and Update the Area Code and Mobile Number"
      });        
      }else*/{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken; 
          //const {registrationNo, gender, emailID, firstName, title, dateOfBirth, applicationDate, applicationNo, areaCode, mobileNumber} = item;
          const registrationno=item.Attributes[0]['Value'];
          const params={
            registrationNo:registrationno,
           // gender: gender,
           // emailID:emailID,
            //firstName:firstName,
            //title:title,
            //dateOfBirth:dateOfBirth,
            //applicationNo:applicationNo,
            //applicationDate:applicationDate,
            //areaCode:areaCode,
            //mobileNumber:mobileNumber
          }
          //console.log("Params : ", params);
        //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
          await axios.post(`${config.api.invokeURL}/dashboard/accounts/recreate`,params, {  
          headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("Response : ", response);
         if(response.status===202){
          await fetchCurrentYearDetails();
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Login Account Created Successfully!"
          });  
          }else{
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: response.data
          });  

          }
          setLoaded(true)
      }).catch((error)=>{
       // console.log("Error : ", error);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      setLoaded(true);
      window.scrollTo(0,0);
    })
  }
    }catch(error){
     // console.log("Error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    window.scrollTo(0,0);
    }
    window.scrollTo(0,0);
  }



  /*   const [q, setQ] = useState("");

     function search(items) {
      return items.filter((item) => {
          return searchParam.some((newItem) => {
              return (
                  item[newItem]
                      .toString()
                      .toLowerCase()
                      .indexOf(q.toLowerCase()) > -1
              );
          });
      });
  }*/

return (
    <>
    <Helmet>
      <title>Member's Login - Admin Office - Archives</title>
      <meta name="description" content="Members Login - Admin Office - Archives" />
      <meta name="keywords" content="Member Login - Admin Office - Archives" /> 
    </Helmet>

      <Preloader show={loaded ? false : true} />
      <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
      </Alert>
     {/*Update Email*/}
     <div>
        <Modal toggle={emailToggle} isOpen={!updateEmailCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={emailToggle}>
                    Update - Email Address
                    </ModalHeader>
                    <ModalBody>    
                    <div className="text-center">
                      <EmailUpdateModal emailToggle={emailToggle} fetchCurrentYearDetails={fetchCurrentYearDetails} selectedEmail={selectedEmail} selectedRegistrationNo={selectedRegistrationNo}/>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button onClick={emailToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
        </div>

        {/*Payment History
     <div >
          <Modal toggle={historyToggle} isOpen={!historyCollapsed} style={{top:'10%'}} >
          <div >
             <ModalHeader toggle={historyToggle}>
                    </ModalHeader>
                    <ModalBody>
                      <HistoryView selectedItem={selectedItem} />
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={historyToggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
     
        </div>*/}

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item> Member</Breadcrumb.Item>
            <Breadcrumb.Item active> Login Account</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Member Login Management</h4>
        </div>
        {/*<div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
      </div>*/}
      </div>

      <div className="table-settings">
       
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="ApplicationID" value={searchTopic} onChange={(event)=>{handleSearchTopicSelection(event)}}>
                <option value="ApplicationID">Application No</option>
                <option value="ApplicatantName">Name</option>
                  <option value="EmailID">Email Address</option>
                  <option value="JoiningYear">Joining Year</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              {searchTopic==="JoiningYear"?           
              <Form.Select value={searchValue} 
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {batchYear.map(item => {
                    return (
                    <option key={item} value={item}>{item}</option>
                    );
                  })}
            </Form.Select>:searchTopic==="Status"?           
              <Form.Select value={searchValue} 
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                <option value="Active">Active</option>
                <option value="In-Active">In Active</option>
                <option value="Pending Approval">Pending Approval</option>
                <option value="Pending Payment">Pending Payment</option>
                <option value="Expired">Expired</option>
                <option value="Declined">Declined</option>
            </Form.Select>:searchTopic==="SpecialAccess"?           
              <Form.Select value={searchValue} 
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>:
            <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{fetchApplicationDetails(event)}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
        </div>

{missingResults.length>0?<><h3>{missingResults.length} - Records found without Login accounts</h3>
  <Table ref={componentRef} hover responsive>
    <thead>
        <tr>
        <th>#</th>
        <th>Registration No</th>
        <th>Name</th>
        <th>Email</th>  
        <th>Mobile</th>    
        <th>Action</th>
        </tr>
    </thead>
    <tbody>
    {missingResults.map((item, index)=>

    <tr key={index}>
    <th scope="row">{index+1}</th>
    <td>{item.applicationNo}</td>
    <td>{item.applicantName}</td>
    <td> {item.applicantEmailID}</td>
    <td>{item.applicantAreaCode}{item.applicantContactNo}</td>
    <td> 
    <Dropdown as={ButtonGroup}>
    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
      <span className="icon icon-sm">
        <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
      </span>
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item onClick={(event)=>{handleCreateAccount(event,item)}} >
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Create Account
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
    </td>
    </tr>)}
    </tbody>
    </Table></>:null}
    {searchResults.length>0?<><h3>{searchResults.length} - Records found with Login accounts</h3>
    <Table ref={componentRef} hover responsive>
      <thead>
        <tr>
        <th>
            #
        </th>
        <th>
            Registration No
        </th>
        <th>
            Username
        </th>
        <th>
            Account Status
            </th>  
            <th>
            Login Status
            </th>  
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
    {searchResults.length>0 && searchResults.map((item, index)=>
    <tr key={index}>
    <th scope="row">
        {index+1}
    </th>
    <td>
        {item.Attributes[0]['Value']}
    </td>
    <td>
        {item.Attributes[1]['Value']}
    </td>
    <td> 
      {item.Enabled?"Enabled":"Disabled"}
    </td>
    <td> 
      {item.UserStatus}
    </td>
    <td> 
    <Dropdown as={ButtonGroup}>
    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
      <span className="icon icon-sm">
        <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
      </span>
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item onClick={(event)=>{handleEmailUpdateView(event,item)}} >
        <FontAwesomeIcon icon={faExchange} className="me-2" /> Change Email Address
      </Dropdown.Item>
      <Dropdown.Item onClick={(event)=>{handleRecreateAccount(event,item)}} >
        <FontAwesomeIcon icon={faExchange} className="me-2" /> Re-Create Account
      </Dropdown.Item>

    </Dropdown.Menu>
  </Dropdown>
    </td>
    </tr>)}
    </tbody>
    </Table></>:null}
    </>
  );
};
