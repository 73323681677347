import React, { useEffect, useContext,useState } from 'react';
import axios from "axios";
import { Auth } from "aws-amplify";
//import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
//import {Modal, ModalBody, ModalHeader, ModalFooter, Alert, Card  } from "reactstrap";
import { Col, Row} from '@themesberg/react-bootstrap';
//import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { ChartsGoogle } from './ChartsGoogle';
import Preloader from "../../components/Preloader";

const config = require('../../config.json');

export default function RegistrationActiveInsights() {
    const { alertDetails, setAlertDetails } = useContext(ProductContext);
   // const [regInsights, setRegInsights] = useState('');
    const [loaded, setLoaded] = useState(true);
    const [regYearCount, setRegYearCount] = useState();
    const [googleRegData, setGoogleRegData]= useState();
    const [isRegYearCount, setIsRegYearCount]=useState(false);
    useEffect(()=>{
        fetchRegDetails()
    },[])

    const fetchRegDetails=async()=>{
        try{      
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;  
            await axios.get(`${config.api.invokeURL}/dashboard/snapshot/activeregdetails`, {
              headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
              }
            }).then(async(response) => {
              //  console.log("response : ", response);
                processData(response.data)
                setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: response.data.length + " - student's records found!"
            });  
            setLoaded(true);
          }).catch((error)=>{
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          });        
          setLoaded(true);
        })
      }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      }
    }

    const processData=(rawData)=>{
        var regChartHeader=[];
        var regChartValues=[];
        const chartHearder=["Year","Active Registration Count"];
        regChartHeader.push(chartHearder);

        for(const data in rawData){
            const ttt=[data, rawData[data]];
            regChartHeader.push(ttt);
           // regChartHeader.push(rawData[data]);

            regChartValues.push(rawData[data]);
        //    console.log(data," - ", rawData[data]);

        }
        const regChartData={};
        regChartData.labels=regChartHeader;
        regChartData.series=regChartValues;
        setRegYearCount(regChartData);
        //console.log("Data : ", regChartData);
        var tdata=[]
        tdata.push(chartHearder);
        tdata.push(regChartHeader);
        tdata.push(regChartValues);
        setGoogleRegData(regChartHeader);
        setIsRegYearCount(true);

    }


  return (
  <>
    <Preloader show={loaded ? false : true} />
    {/*sRegYearCount?
    <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Registration (Last 5 years)"
            value="10,567"
            percentage={10.57}
            regYearCount={regYearCount}
          />
        </Col>
        </Row>:null*/}
        {isRegYearCount?
    <Row className="justify-content-md-center">
      <Col xs={12} className="mb-4 d-none d-sm-block">
        <ChartsGoogle regYearCount={googleRegData} titleMsg="Year over Year - Active Registration Count"/>
      </Col>
    </Row>:null}
  </>
  )
}
