import React,{useEffect, useState} from "react";
import { Chart } from "react-google-charts";

export function GaugeGoogle(props) {
    const {googleRegData, totalCount} = props;
    //const [activeCount, setActiveCount]=useState([]);
    const [options, setOptions]= useState({});

useEffect(()=>{
    //console.log("Props : ", props);
    //console.log("Pending approval : ", resData["Pending Approval"]);
    //const totalCount=resData["Pending Approval"]+resData["Pending Payment"] + resData["Active"] + resData["In-Active"]+resData["Declined"]+resData["Expired"];
    const redFrom=totalCount-(totalCount*20/100);
    const yellowFrom=totalCount-(totalCount*40/100);
    const minorTicks=10;

    const toptions = {
        width: 900,
        height:200,
        redFrom: redFrom,
        redTo: totalCount,
        yellowFrom: yellowFrom,
        yellowTo: redFrom,
        minorTicks: minorTicks,
        max:totalCount,
        forceIFrame:true
    };
   /* const tactiveCount=[
        ["Label","Value"],
        ["Active",Active],
        ["Not-Active",All-Active],
        ["Total",All]
        ];
    setActiveCount(tactiveCount);*/
    setOptions(toptions);
},[])



return (
<>
    <Chart
      chartType="Gauge"
      data={googleRegData}
      options={options}
    />
</>
  );
}
