
import React, { useState, useContext } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faInr, faChartPie, faSignOutAlt, faTimes, faGlobe, faImage, faBox, faNewspaper, faPen, faHome, faSearch, faBook, faCheckDouble, faImagePortrait, faMapLocation, faPerson, faLock, faCalendar, faMailBulk, faCalendarDay, faVideo, faVideoCamera, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link , useHistory} from 'react-router-dom';
import { Auth } from "aws-amplify";
import { ProductContext } from "../ProductProvider";
//import 'simplebar-react/dist/simplebar.min.css';
//import "simplebar/src/simplebar.css";

import { Routes } from "../routes";
//import ThemesbergLogo from "../assets/img/themesberg.svg";
//import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
//import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
//import { faSearchengin } from "@fortawesome/free-brands-svg-icons";

export default (props = {}) => {
  const history=useHistory();
  const { loggedUser, setLoggedUser, myProfileImage } = useContext(ProductContext);
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const handleSignOut=async()=>{
    try{
      await Auth.signOut()
      .then((res)=>{
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
         // currentStatus:'',
          DOB:'',
          profile:'',
          provinceName:'',
          website:'',
          isAuthenticated:false,
          isAdmin:false
        }); 
        history.push(Routes.Signin.path);

      }).catch((error)=>{
        console.log("Error ", error.message);
      })
    }catch(error){
      console.log("Error ", error.message);
    }
  }

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey} style={{backgroundColor:'#262B40'}}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center" style={{backgroundColor:'#262B40'}}>
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={""} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={myProfileImage} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, {loggedUser.name}</h6>
                  <Button as={Link} variant="secondary" size="xs" onClick={handleSignOut} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">

              <NavItem title="ARCHIVE" link={Routes.WelcomeDash.path} icon={faHome} />

              <CollapsableNavItem eventKey="registration/" title="Registration" icon={faUserCircle}>
                <NavItem title="New" link={Routes.NewRegistration.path} badgeText="Add"/>
                <NavItem title="Manage" link={Routes.ManageRegistration.path}   />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="members/" title="Member" icon={faPerson}>
              <NavItem title="Students Search" icon={faSearch} link={Routes.StudentsDetails.path} />
              {/*<NavItem title="Course Status" icon={faMapLocation} link={Routes.LessonJourney.path}   />*/}
              <NavItem title="Students Login" icon={faLock} link={Routes.StudentMgmt.path}   />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="course/" title="Catalogue" icon={faBook}>
              <NavItem title="New" icon={faPlus} link={Routes.CourseVideosAdd.path} />
              <NavItem title="Search" icon={faVideo} link={Routes.CourseVideosInfo.path} />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="fees/" title="Subscription" icon={faInr}>
                <NavItem title="New" link={Routes.ManageFee.path} badgeText="View"/>
                <NavItem title="Search" link={Routes.ListFees.path}   />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="CatalogueRequest/" title="Catalogue Request" icon={faPen}>
                <NavItem title="New Request" link={Routes.NewCatalogue.path} badgeText="New"/>
                <NavItem title="Pending Request" link={Routes.PendingCatalogue.path} badgeText="View"/>
                <NavItem title="Search" link={Routes.SearchCatalogue.path}   />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-2 border-indigo" />

              <NavItem title="InSights" icon={faChartPie} link={Routes.Insights.path} />
              <NavItem title="My Profile" icon={faImagePortrait} link={Routes.StaffProfile.path} />
              {/* <NavItem title="Admin Accounts" icon={faNewspaper} link={Routes.StaffRegistration.path} /> */}
              <NavItem title="Change Password" link={Routes.ResetPassword.path} icon={faKey} />
             {/*} <NavItem title="Log out" icon={faSignOutAlt} link={Routes.Signin.path} onClick={handleSignOut}/>

              <NavItem external title="Themesberg" link="https://themesberg.com" target="_blank" image={ThemesbergLogo} />*/}
              <Button as={Link} to="#" variant="secondary" className="upgrade-to-pro" onClick={handleSignOut}><FontAwesomeIcon icon={faSignOutAlt} className="me-1" /> Log Out</Button>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
