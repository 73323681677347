import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHome, faSearch, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from '@themesberg/react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { downloadCatalogueHeaders } from "../../ProData";
import {Helmet} from 'react-helmet-async';
import AddCatalogue from "./AddCatalogue";
import { catalogCategoryData } from "../../ProData";

const config = require('../../config.json');

//import { TransactionsTable } from "../../components/Tables";

export default () => {
  const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, setCatalogueFormType, catalogue, setCatalogue } = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('CatalogueID');
  const [category, setCategory]=useState([{catid:'',category:'Goa',specialAccess:false}]);
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const [selfNo, setSelfNo]= useState(catalogCategoryData);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      //processBatchYear();
      initializeValues();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }

  },[loggedUser])

  const initializeValues=async()=>{
    var uniqueArray = removeDuplicates("category",catalogCategoryData );
    var sortedcourseArray = uniqueArray.sort((a, b) => {return a.catid - b.catid;});
    setCategory(sortedcourseArray);
    processCatalogueSelect(sortedcourseArray[0].category);
    
  }

  const removeDuplicates=(tprop, coursesOffered)=> {
      var newArray = [];
      var lookupObject  = {};
      for(var i in coursesOffered) {
        lookupObject[coursesOffered[i][tprop]] = coursesOffered[i];
        //console.log("item : ",coursesOffered[i][tprop] );
      }

      for(i in lookupObject) {
          newArray.push(lookupObject[i]);
      }
      return newArray;
  }

  const processCatalogueSelect=(tempCatid)=>{
    const tcat = catalogCategoryData.filter((item)=>item.category===tempCatid);
    setCatalogue({...catalogue, category:tempCatid, 
      self:tcat[0].catid,
      specialAccess:tcat[0].specialAccess
    });
    //console.log("self no : ", tcat);
    setSelfNo(tcat);
  }


  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=(event, item)=>{
    event.preventDefault();
    try{
      setCatalogueFormType('View');
      setCatalogue(item);
      toggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  const handleApplicationEdit=(event, item)=>{
    event.preventDefault();
    try{
      setCatalogueFormType('Edit');  
      setCatalogue(item);
      newToggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  

      }else{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;  
        //console.log("search : ", searchTopic, searchValue);
      await axios.get(`${config.api.invokeURL}/catalogue/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
       //console.log("response : ", response.data[0]);
        if(response.data.length>0)
        {
       //     console.log("order status")
            setSearchResults(response.data);
        }
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: response.data.length + " - catalogue's records found!"
      });  
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  /*const validateFields=()=>{
    const {title,firstName, dateOfBirth, gender, maritialStatus,
      occupation, religion, mobileNumber, emailID, addressStreet, addressState,
      addressPincode, nationality, motherTongue, englishProficiency}=profileDetails;
      var returnValue="passed";
      if(title===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Title cannot be Empty"
      }); 
      returnValue="failed";
    }else  if(firstName===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Name cannot be Empty"
    }); 
    returnValue="failed";
  }else  if(dateOfBirth===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Date of Birth cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(gender===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Gender cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(maritialStatus===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Maritial Status cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(occupation===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Occupation cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(religion===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Religion cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(mobileNumber===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Mobile Number cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(emailID===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Email Address cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(addressStreet==="" || addressPincode==="" || addressState===""){
      console.log(addressStreet, addressState, addressPincode);
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Address cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(nationality===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Nationality cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(motherTongue===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Mother Tongue cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(englishProficiency===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "English Proficiency cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(imageFile===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Attach your Photo. Photo is must for registration!"
  }); 
  returnValue="failed";
  }else  if(idProofFile===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Attach one Govt approved photo ID proof! Its must."
  }); 
  returnValue="failed";
  }else  if(certProofFile===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Attach UG/PG certificate. It's mandatory for registration."
  }); 
  returnValue="failed";
  }else  {
    returnValue="passed";
  
  }
  window.scrollTo(0,0);
  return returnValue;
  }*/

  const handleSubmit=async(event)=>{
    event.preventDefault();
    try{
    //  console.log("value : ", emailCodeEntered, emailCodeReceived);
    alertClose();
   // const validationResult=validateFields();
   /*const validationResult="passed";
    if(validationResult==="passed"){

      setLoaded(false);
      const clientID = "ARCHIVE";
      const applicationno= applicantNo;
      const tpaymentStatus="Pending";
      const tupdatedOn=await generateDate();
      //const applicationStatus="Pending Approval";
      var tavatar="";
      if(imageFile){
        tavatar= applicationno+".jpeg";
      }else{
        tavatar=applicantNo;
      }
      const applicationStatus="Pending Approval";
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //  console.log("Params : ", params);
      await axios.patch(`${config.api.invokeURL}/dashboard/registration/${applicationno}`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
       //   console.log("response : ", response); 
       if(imageFile){
          await postOnS3(applicationno, imageFile,"Profile");
       }
       await handleSearch();
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: applicationno + " - Successfully Updated."
        }); 
        window.scrollTo(0,0);
        newToggle();
        setLoaded(true);
        }).catch((error)=>{
       //   console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
    }*/
      
      }catch(error){
  //      console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const handleSearchTopicSelection=(event)=>{
    if(event.target.value==="Category"){
      setSearchValue(category[0].category);
    }else if(event.target.value==="CatalogueStatus"){
      setSearchValue("Active");
    }else{
      setSearchValue('')
    }
    setSearchTopic(event.target.value)
  }

  return (
    <>
    <Helmet>
      <title>Catalogue Search - Admin Office - Archives</title>
      <meta name="description" content="Catalogue Search - Admin Office - Archives" />
      <meta name="keywords" content="Catalogue Search - Admin Office - Archives" /> 
    </Helmet>

      <Preloader show={loaded ? false : true} />
      <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
      </Alert>
        
        {/*View*/}
        <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div  ref={componentModelRef}>
            <ModalHeader  toggle={toggle}>
               Catalogue Form - View
            </ModalHeader>
            <ModalBody >    
              <AddCatalogue /> 
            </ModalBody>
            <ModalFooter>
            {' '}
            <ReactToPrint
              content={reactToPrintContentModal}
              documentTitle="AwesomeFileName"
              trigger={reactToPrintTriggerModal}
            />
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>       
        </Modal>
        </div>
        
        {/*Edit*/}
        <Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
          <ModalHeader toggle={newToggle}>
          Catalogue Form - Edit
          </ModalHeader>
          <ModalBody>    
          <div>
          <AddCatalogue /> 
          </div>
          </ModalBody>
          <ModalFooter>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
          </ModalFooter>
        </Modal>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Catalogue</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Catalogue Search</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="catalogue"
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadCatalogueHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="CatalogueID" value={searchTopic} onChange={(event)=>{handleSearchTopicSelection(event)}}>
                <option value="CatalogueID">Accession No</option>
                <option value="Category">Category</option>
                <option value="Title">Title</option>
                <option value="Author">Author</option>
                  <option value="CatalogueStatus">Status</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              {searchTopic==="Category"?           
              <Form.Select value={searchValue} defaultValue={category[0].category}
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {category.map(item => {
                    return (
                    <option key={item.catid} value={item.category}>{item.category}</option>
                    );
                  })}
            </Form.Select>:searchTopic==="CatalogueStatus"?           
              <Form.Select value={searchValue} defaultValue="Active"
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                <option value="Active">Active</option>
                <option value="In-Active">In Active</option>
            </Form.Select>:
            <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

      <Table ref={componentRef} hover responsive>
             <thead>
                <tr>
                <th>#</th>
                <th>Accession No</th>
                <th>Title</th>
                <th>Author</th>
                <th>Category</th>
                <th>Status</th>
               <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {searchResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.catalogueID}</td>
            <td>{item.title}</td>
            <td>{item.author}</td>
            <td>{item.category}</td>
            <td>{item.catalogueStatus==="Active"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.catalogueStatus}</p>
            :<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.catalogueStatus}</p>}
            </td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationEdit(event, item)}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
      </Table>
    </>
  );
};
