import React, {useState, useContext, useEffect} from 'react';
import RegistrationForm from './RegistrationForm';
import { Alert} from 'reactstrap';
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import ApplicationStatus from './ApplicationStatus';
import { Button } from 'react-bootstrap';
import axios from "axios";
import {Auth} from 'aws-amplify';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function NewRegistration() {

    const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, subscriberDetails, setsubscriberDetails } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(true);
    const [applicationProcessed, setApplicationProcessed]=useState(false);
    const [app, setApp]=useState({appNo:'', appMsg:''});
    const [imageFile, setImageFile] = useState('');
    const [startDate, setStartDate]= useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(()=>{
      if(loggedUser.isAuthenticated){
        alertClose();
        resetValues();
      }else{
      validateLogin();
      alertClose();
      resetValues();
      }
      return()=>{
        alertClose();
        resetValues();
      }
    },[loggedUser])

    const generateApplicationNo=()=>{
        const current_datetime = new Date();
        const fName=subscriberDetails.applicantName.trim().replace(/\s/g, "");
        //const tusername=memberInfo.name.trim().replace(/\s/g, "");
        const tfName= fName.substring(0,2).toUpperCase();
        const lName=subscriberDetails.applicantName.trim().replace(/\s/g, "");
        //const tusername=memberInfo.name.trim().replace(/\s/g, "");
        const tlName= lName.substring(0,2).toUpperCase();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;
        //console.log("acc : ", accountNo);
      
    }

    const assignNewSubcriptionDates=async()=>{
      //var tempDate= new Date(subscriberDetails.endDate);
      var tempDate= new Date();
      tempDate.setDate(tempDate.getDate() + 1);
      var date_date = ((tempDate.getDate()).toString());
      var date_value="";
      date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
      var date_month = (tempDate.getMonth() + 1).toString();
      var month_value="";
      date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
      const tempYear = (tempDate.getFullYear()).toString();
      var tempRenew = "";
      const newStartDate = tempRenew.concat(tempYear,"-",month_value,"-",date_value);
      //const tempendDate= new Date(subscriberDetails.endDate);
      const tempendDate= new Date();
      var date_date1 = ((tempendDate.getDate()).toString());
      var date_value1="";
      date_date1.length===1?(date_value1=date_value1.toString().concat(0,date_date1)):(date_value1=date_date1);
      var date_month1 = (tempendDate.getMonth() + 2).toString();
      var month_value1="";
      date_month1.length===1? (month_value1=month_value1.concat(0,date_month1)):(month_value1=date_month1);
      const tempYear1 = (tempendDate.getFullYear()).toString();
      var tempRenew1 = "";
      const newEndDate = tempRenew1.concat(tempYear1,"-",month_value1,"-",date_value1);
      await setStartDate(newStartDate);
      await setEndDate(newEndDate);
      setsubscriberDetails({...subscriberDetails, 
        registrationDate:newStartDate,
          startDate:newStartDate,
          endDate:newEndDate,
      });
  }

    const handleNewForm=()=>{
        resetValues();
        setApp({...app, appNo:'', appMsg:''});
        setApplicationProcessed(false);
        alertClose();
    }

    const resetValues=async()=>{
      await assignNewSubcriptionDates();
      console.log("start date :", startDate);
      const applicationDate=await generateDate();
      setsubscriberDetails({...subscriberDetails,applicationNo:'',applicantName:'', applicantContactNo:'', applicantEmailID:'', applicantAreaCode:'+91', applicantAddress:'', institutionName:'',
      institutionAddress:'', institutionAreaCode:'+91', institutionContactNo:'', institutionEmailID:'', researchProjectTitle:'', materialRequired:[], guideName:'', guideDesignation:'', 
      guideAddress:'', guideContactNo:'',  guideAreaCode:'+91', guideEmailID:'', applicationStatus:'', registrationDate:applicationDate, updatedBy:'', updatedOn:'', startDate:applicationDate, endDate:applicationDate});
      setImageFile('');
    }

    const validateFields=()=>{
      const {applicantName, applicantContactNo, applicantEmailID, applicantAreaCode, applicantAddress, institutionName,
      institutionAddress, institutionContactNo, institutionEmailID, researchProjectTitle, materialRequired, guideName, 
      guideDesignation, guideAddress, guideContactNo, guideEmailID}=subscriberDetails;
        var returnValue="passed";
        /*if(applicationNo===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Applicant Number cannot be Empty! Refresh the page and try again!"
        }); 
        returnValue="failed";
      }else */if(applicantName===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Full Name cannot be Empty"
      }); 
      returnValue="failed";
    }else if(applicantContactNo===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Contact Number cannot be Empty"
    }); 
    returnValue="failed";
    }else if(applicantEmailID===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Email ID cannot be Empty"
    }); 
    returnValue="failed";
    }else if(applicantAreaCode===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Area Code cannot be Empty"
    }); 
    returnValue="failed";
    }else if(applicantAddress===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Address cannot be Empty"
    }); 
    returnValue="failed";
    }else if(institutionName===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Name cannot be Empty"
    }); 
    returnValue="failed";
    }else if(institutionAddress===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Address cannot be Empty"
    }); 
    returnValue="failed";
    }else if(!applicantEmailID.match(validRegex)){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Email address is not in right format!"
    }); 
    returnValue="failed";
    }else if(institutionContactNo===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Contact Number cannot be Empty"
    }); 
    returnValue="failed";
    }else if(institutionEmailID===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Email ID cannot be Empty"
    }); 
    returnValue="failed";
    }else if(!institutionEmailID.match(validRegex)){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Email address is not in right format!"
    }); 
    returnValue="failed";
    }else if(researchProjectTitle===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Research Title cannot be Empty"
    }); 
    returnValue="failed";
    }else if(materialRequired.length<1){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Materials Required cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideName===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Name cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideDesignation===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Designation cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideAddress===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Address cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideContactNo===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Contact No cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideEmailID===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Email ID cannot be Empty"
    }); 
    returnValue="failed";
    }else if(!guideEmailID.match(validRegex)){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Email address is not in right format!"
    }); 
    returnValue="failed";
    }else  if(imageFile===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Attach your Photo. Photo is must for registration!"
    }); 
    returnValue="failed";
    }else {
    returnValue="passed";
    }
    window.scrollTo(0,0);
    return returnValue;
    }

    const postOnS3 = async(applicationno,timageFile,inputType)=>{
      try{
          //  console.log("NewImage : ", newImage);
          var tKeyName="";
          var newFileName="";
          if(inputType==="Profile"){
            tKeyName="avatar/"+applicationno;   
            newFileName=applicationno+".jpeg";
          }else if (inputType==="IDProof"){
            tKeyName="avatar/"+applicationno+"/docs";  
            newFileName=applicationno+"_idproof.jpeg"; 
          }else if (inputType==="CERTProof"){
            tKeyName="avatar/"+applicationno+"/docs";  
            newFileName=applicationno+"_certproof.jpeg"; 
          }   
        
        //console.log("newfilename :", newFileName);
        // const newImage="";
        var file = timageFile;
        var blob = file.slice(0, file.size, 'image/jpeg'); 
        const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
        // console.log("New file : ", newFile);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const bucketName=config.s3.bucketName + tKeyName;
        // await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
        // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
        await axios.get(`${config.api.invokeURL}/dashboard/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
              headers:{
                  Authorization: access_token,
                  'x-api-key': config.api.key
              }
          }).then(async(response)=>{
            //  console.log("Response : ", response);
            const res=await fetch(response.data.uploadURL, { method: 'PUT',
              headers:{
                  "Content-Type":"image/jpeg"
                },body: newFile});
              //  console.log("Res : ", res);
                if(res.status===200){
              //  setNewProfileImage(newFileName);
                //  fetchGalleryImages();
                  // setProfileImage(newFileName);
                    //var timestamp = new Date().getTime();     
                  // var tprofile = document.getElementById("profile");
                    //var imgsrc="";
                    //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                    //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                  // tprofile.src=imgsrc;
                  //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                    //console.log("tprofle : ", tprofile.src);
                }
          }).catch((error)=>{
              // console.log("error1:", error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
            // setDispMsg(error.message);
            // hideLoader();
          })
      
      }catch(error){
        // console.log("error1:", error);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      }
    
    }

    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
        //  console.log("value : ", emailCodeEntered, emailCodeReceived);
        alertClose();
        const validationResult=validateFields();
        if(validationResult==="passed"){
          setLoaded(false);
          const {applicantName, applicantContactNo, applicantEmailID, applicantAreaCode, applicantAddress, institutionName,
          institutionAddress, institutionAreaCode, institutionContactNo, institutionEmailID, researchProjectTitle, materialRequired, 
          guideName, guideDesignation, guideAddress, guideContactNo,  guideAreaCode, guideEmailID, specialAccess, comments}=subscriberDetails;
          const clientID = "ARCHIVE";
          const applicationno= await generateApplicationNo();
          const requestid="REQ"+applicationno;
          const applicationDate=await generateDate();
          const applicationStatus="Pending Approval";
          const avatar= applicationno+".jpeg";
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;

          const params={
            clientID:clientID, applicationNo: applicationno, applicantName:applicantName, applicationDate: applicationDate, applicationStatus:applicationStatus,
            applicantContactNo:applicantContactNo, applicantEmailID:applicantEmailID, applicantAreaCode:applicantAreaCode, applicantAddress:applicantAddress,
            institutionName:institutionName, institutionAddress:institutionAddress, institutionAreaCode:institutionAreaCode, institutionContactNo:institutionContactNo, 
            institutionEmailID:institutionEmailID, researchProjectTitle:researchProjectTitle, materialRequired:materialRequired,
            guideName:guideName, guideDesignation:guideDesignation, guideAddress:guideAddress, guideAreaCode:guideAreaCode, guideContactNo:guideContactNo,
            guideEmailID:guideEmailID, avatar:avatar, updatedBy:applicationno, updatedOn:applicationDate, registrationDate:applicationDate, startDate:applicationDate, 
            endDate:applicationDate, specialAccess:specialAccess, comments: comments, requestID:requestid, requestDate:applicationDate, requestStatus:'Pending'};
          //console.log("Params : ", params);
          await axios.post(`${config.api.invokeURL}/dashboard/registration/${applicationno}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
           //   console.log("response : ", response); 
              await postOnS3(applicationno, imageFile,"Profile");
              setApplicationProcessed(true);
              setApp({...app, appNo:applicationno, appMsg:"Congratulations and Your Application Registration is successful. Your application will be reviewed throughly and further updates will be shared via Email. You can also get the latest updates from our portal."})
              setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: applicationno + " - Successfully Registered."
            }); 
            window.scrollTo(0,0);
            resetValues();
            setLoaded(true);
            }).catch((error)=>{
           //   console.log("error : ", error);
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              window.scrollTo(0,0);
              setLoaded(true);
            })
        }
          }catch(error){
      //      console.log("error : ", error);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        window.scrollTo(0,0);
        setLoaded(true);
        }
    }

  return (
  <>
    <Helmet>
      <title>Member Registration - Admin Office - Archives</title>
      <meta name="description" content="Member Registration - Admin Office - Archives" />
      <meta name="keywords" content="Member Registration - Admin Office - Archives" /> 
    </Helmet>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>
    { !applicationProcessed ? 
    <div>
      <RegistrationForm formType={"New"} setImageFile={setImageFile}/>
      <div className="mt-3 text-center" style={{padding:'30'}}>
        <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Submit</Button>
        {'                     '}
        <Button variant="primary" type="submit" onClick={(event)=>{handleNewForm(event)}}>Reset</Button>
      </div>
    </div>
    :<ApplicationStatus app={app}  handleNewForm={handleNewForm}/>}
    </>
  )
}
