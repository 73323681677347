import React, { useEffect, useState, useContext } from 'react';
import { Auth } from "aws-amplify";
import axios from "axios";
import { Alert } from 'reactstrap';
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch , faHome} from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, InputGroup, Breadcrumb, ButtonGroup } from '@themesberg/react-bootstrap';

import CatalogRequestForm from '../Registrations/CatalogRequestForm'
import NewReistrationForm from '../Registrations/NewRegistrationForm';
const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function NewCatalogue() {
    const { alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, loggedUser, subscriberDetails, setsubscriberDetails} = useContext(ProductContext);
    const [searchTopic, setSearchTopic] = useState('ApplicationID');
    const [searchValue, setSearchValue] = useState('');
    const [loaded, setLoaded] = useState(true);
    const [searchResults, setSearchResults] = useState([]);

    //const [formType, setFormType] = useState("Edit");

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          alertClose();
          //setFormType("Edit");
        }else{
        validateLogin();
        alertClose();
        }
        return()=>{
          alertClose();
        }
       
    },[])

  const assignNewSubcriptionDates=()=>{
    //var tempDate= new Date(subscriberDetails.endDate);
    var tempDate= new Date();
    tempDate.setDate(tempDate.getDate() + 1);
    var date_date = ((tempDate.getDate()).toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_month = (tempDate.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    const tempYear = (tempDate.getFullYear()).toString();
    var tempRenew = "";
    const newStartDate = tempRenew.concat(tempYear,"-",month_value,"-",date_value);
    //const tempendDate= new Date(subscriberDetails.endDate);
    const tempendDate= new Date();
    var date_date1 = ((tempendDate.getDate()).toString());
    var date_value1="";
    date_date1.length===1?(date_value1=date_value1.toString().concat(0,date_date1)):(date_value1=date_date1);
    var date_month1 = (tempendDate.getMonth() + 2).toString();
    var month_value1="";
    date_month1.length===1? (month_value1=month_value1.concat(0,date_month1)):(month_value1=date_month1);
    const tempYear1 = (tempendDate.getFullYear()).toString();
    var tempRenew1 = "";
    const newEndDate = tempRenew1.concat(tempYear1,"-",month_value1,"-",date_value1);

    //setSubscribedAmount(amount);
    //setFinalAmount(amount);
    //setsubscriberDetails({...subscriberDetails, endDate:renewDate});
    setsubscriberDetails({...subscriberDetails, 
        startDate:newStartDate,
        endDate:newEndDate,
    });
}

  /*const handleNewForm=()=>{
      resetValues();
      //setApp({...app, appNo:'', appMsg:''});
      //setApplicationProcessed(false);
      alertClose();
  }*/

  const resetValues=async()=>{
    const applicationDate=await generateDate();
    setsubscriberDetails({...subscriberDetails,applicationNo:'',applicantName:'', applicantContactNo:'', applicantEmailID:'', applicantAreaCode:'+91', applicantAddress:'', institutionName:'',
    institutionAddress:'', institutionAreaCode:'+91', institutionContactNo:'', institutionEmailID:'', researchProjectTitle:'', materialRequired:[], guideName:'', guideDesignation:'', 
    guideAddress:'', guideContactNo:'',  guideAreaCode:'+91', guideEmailID:'', applicationStatus:'', registrationDate:applicationDate, updatedBy:'', updatedOn:'', startDate:applicationDate, endDate:applicationDate});
    //setImageFile('');
    await assignNewSubcriptionDates();
  }


    const handleSearch=async()=>{
        //event.preventDefault();
        setSearchResults([]);
        try{
          if(searchValue===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Search Value is empty Enter the search input and then hit Search button!"
          });  
          }else{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;  
            var tsearchValue=searchValue
            if(searchTopic==="SubscriberName"){
              tsearchValue=searchValue.toUpperCase();
              setSearchValue(tsearchValue);
            }
            //await axios.get(`${config.api.invokeURL}/dashboard/registration?clientID=${clientID}&applicationStatus=${tsearchValue}`,{
            await axios.get(`${config.api.invokeURL}/dashboard/globalsearch?fetchKey=${tsearchValue}&fetchOption=${searchTopic}`, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
            //console.log("Res : ", response.data[0]);
            if(response.data.length>0)
            {
              if(response.data[0].applicationStatus==="Active"){
                const tresults=response.data[0];
                tresults.institutionName='';
                tresults.institutionAddress='';
                tresults.institutionAreaCode='+91';
                tresults.institutionContactNo='';
                tresults.institutionEmailID=''; 
                tresults.researchProjectTitle='';
                tresults.materialRequired=[];
                tresults.guideName='';
                tresults.guideDesignation='';
                tresults.guideAddress='';
                tresults.guideContactNo='';  
                tresults.guideAreaCode='+91';
                tresults.guideEmailID='';
                setsubscriberDetails(tresults);
                //setFormType("New");
                setSearchResults(response.data);
                //setSubscriberFormType("Add");
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: response.data.length + " - Subscriber's records found!"
              });      
              }else{
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: response.data[0].applicationNo + " - Catalogue Request cannot be placed! The account is in - " + response.data[0].applicationStatus + " status"
              });      
              }
            }else{        
               // setFormType("None");        
            }
            setLoaded(true);
        }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      })
    }
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
        }
    }

    const validateFields=()=>{
      const {applicationNo,applicantName, applicantContactNo, applicantEmailID, applicantAreaCode, applicantAddress, institutionName,
      institutionAddress, institutionContactNo, institutionEmailID, researchProjectTitle, materialRequired, guideName, guideDesignation, guideAddress, guideContactNo, guideEmailID}=subscriberDetails;
        var returnValue="passed";
        /*if(applicationNo===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Applicant Number cannot be Empty! Refresh the page and try again!"
        }); 
        returnValue="failed";
      }else */if(applicantName===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Full Name cannot be Empty"
      }); 
      returnValue="failed";
    }else if(applicantContactNo===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Contact Number cannot be Empty"
    }); 
    returnValue="failed";
    }else if(applicantEmailID===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Email ID cannot be Empty"
    }); 
    returnValue="failed";
    }else if(applicantAreaCode===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Area Code cannot be Empty"
    }); 
    returnValue="failed";
    }else if(applicantAddress===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Address cannot be Empty"
    }); 
    returnValue="failed";
    }else if(institutionName===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Name cannot be Empty"
    }); 
    returnValue="failed";
    }else if(institutionAddress===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Address cannot be Empty"
    }); 
    returnValue="failed";
    }else if(!applicantEmailID.match(validRegex)){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Applicant Email address is not in right format!"
    }); 
    returnValue="failed";
    }else if(institutionContactNo===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Contact Number cannot be Empty"
    }); 
    returnValue="failed";
    }else if(institutionEmailID===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Email ID cannot be Empty"
    }); 
    returnValue="failed";
    }else if(!institutionEmailID.match(validRegex)){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Institution Email address is not in right format!"
    }); 
    returnValue="failed";
    }else if(researchProjectTitle===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Research Title cannot be Empty"
    }); 
    returnValue="failed";
    }else if(materialRequired.length<1){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Materials Required cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideName===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Name cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideDesignation===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Designation cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideAddress===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Address cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideContactNo===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Contact No cannot be Empty"
    }); 
    returnValue="failed";
    }else if(guideEmailID===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Email ID cannot be Empty"
    }); 
    returnValue="failed";
    }else if(!guideEmailID.match(validRegex)){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Guide Email address is not in right format!"
    }); 
    returnValue="failed";
    }else {
    returnValue="passed";
    }
    window.scrollTo(0,0);
    return returnValue;
    }

    const handleSubmit=async(event)=>{
      event.preventDefault();
      try{
      //  console.log("value : ", emailCodeEntered, emailCodeReceived);
      alertClose();
      const validationResult=validateFields();
      if(validationResult==="passed"){
        setLoaded(false);
        const { institutionName, applicationNo,
        institutionAddress, institutionAreaCode, institutionContactNo, institutionEmailID, researchProjectTitle, materialRequired, 
        guideName, guideDesignation, guideAddress, guideContactNo,  guideAreaCode, guideEmailID}=subscriberDetails;
        const clientID = "ARCHIVE";
        const applicationno = applicationNo;
        const requestid="REQ"+applicationno;
        const applicationDate=await generateDate();
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;

        const params={
          clientID:clientID, applicationNo: applicationno, 
          institutionName:institutionName, institutionAddress:institutionAddress, institutionAreaCode:institutionAreaCode, institutionContactNo:institutionContactNo, 
          institutionEmailID:institutionEmailID, researchProjectTitle:researchProjectTitle, materialRequired:materialRequired,
          guideName:guideName, guideDesignation:guideDesignation, guideAddress:guideAddress, guideAreaCode:guideAreaCode, guideContactNo:guideContactNo,
          guideEmailID:guideEmailID, updatedBy:applicationno, updatedOn:applicationDate,  
          requestID:requestid, requestDate:applicationDate, requestStatus:'Pending'};
        //console.log("Params : ", params);
        await axios.post(`${config.api.invokeURL}/catalogue_request`, params,{
          headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }})
          .then(async(response)=>{
         //   console.log("response : ", response); 
            //await postOnS3(applicationno, imageFile,"Profile");
            //setApplicationProcessed(true);
            //setApp({...app, appNo:applicationno, appMsg:"Congratulations and Your Application Registration is successful. Your application will be reviewed throughly and further updates will be shared via Email. You can also get the latest updates from our portal."})
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: applicationno + " - Successfully Registered."
          }); 
          window.scrollTo(0,0);
          resetValues();
          setLoaded(true);
          }).catch((error)=>{
         //   console.log("error : ", error);
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);
          })
      }
        }catch(error){
    //      console.log("error : ", error);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      window.scrollTo(0,0);
      setLoaded(true);
      }
  }

  return (
    <div>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Catalogue Request</Breadcrumb.Item>
            <Breadcrumb.Item active>New</Breadcrumb.Item>
          </Breadcrumb>
          <h4>New Catalogue Request</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
         
         
          </ButtonGroup>
        </div>
      </div>

        <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="ApplicationID" value={searchTopic} onChange={(event)=>{setSearchTopic(event.target.value)}}>
                <option value="ApplicationID">Application No</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            <Form.Control type="text" autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>           
      {searchResults.length>0 && <>
      <NewReistrationForm formType={"View"}/>
      <CatalogRequestForm formType={"Edit"}/>
      <div className="mt-3 text-center">
        <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Submit</Button>
      </div>
      </>}
    </div>
  )
}
